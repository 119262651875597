import React, { useState } from 'react';
import {
  FacebookBigIcon,
  InstagramBigIcon,
  SMSBigIcon,
  WhatsappBigIcon,
} from '../../../assets';
import { useAppData } from '../../../context/AppContext';
import { CampaignDataProps } from './startCampaignModels';
import { updateCampaign } from '../../../core/requests/requests';
import SelectPhoneNumbersModal from './SelectPhoneNumbersModal';

const ChooseMediaView: React.FC = () => {
  const {
    setCurrentStartCampaignView,
    currentCampaign,
    setCurrentCampaign,
    setShowModalBackground,
  } = useAppData();
  const [showSelectPhoneNumberModal, setShowSelectPhoneNumberModal] =
    useState<boolean>(false);
  const currentCampaignId = currentCampaign?.id;

  const handleShowSelectPhoneNumberModal = () => {
    setShowModalBackground(true);
    setShowSelectPhoneNumberModal(true);
  };

  const handleCloseCreateModal = () => {
    setShowSelectPhoneNumberModal(false);
    setShowModalBackground(false);
  };

  const handleSelectPlatform = async (view: string) => {
    try {
      if (view && currentCampaignId) {
        const platformView = view === 'sms' ? 'SMS' : view;

        const campaignPayload: CampaignDataProps = {
          ...currentCampaign,
          platforms: [platformView],
        };
        const { data } = await updateCampaign(campaignPayload);
        if (data) {
          setCurrentCampaign(data);
        }
      }
    } catch (error) {
      console.log(error);
    }

    handleShowSelectPhoneNumberModal();
  };

  return (
    <div className="d-flex-start-start w-100 mb-1_5rem ">
      <div className="d-flex-row w-100 b-1 b-radius-0_5rem shadow-box-3">
        <div
          className="d-flex-center w-25 h-14rem br-1 opacity-0_5"
          //onClick={() => handleViewChange('instagram')}
        >
          <div className="d-flex-center h-7_5rem w-7_5rem">
            <InstagramBigIcon />
          </div>
          <span className="text-inter-bold-1rem pt-1rem">Instagram</span>
        </div>
        <div
          className="d-flex-center w-25 h-14rem br-1 opacity-0_5"
          //onClick={() => handleViewChange('facebook')}
        >
          <div className="d-flex-center h-7_5rem w-7_5rem">
            <FacebookBigIcon />
          </div>
          <span className="text-inter-bold-1rem pt-1rem">Facebook</span>
        </div>
        <div
          className="d-flex-center w-25 h-14rem br-1 opacity-0_5"
          //onClick={() => handleViewChange('whatsapp')}
        >
          <div className="d-flex-center h-7_5rem w-7_5rem">
            <WhatsappBigIcon />
          </div>
          <span className="text-inter-bold-1rem pt-1rem">WhatsApp</span>
        </div>
        <div
          className="d-flex-center w-25 h-14rem hovered"
          onClick={() => handleSelectPlatform('sms')}
        >
          <div className="d-flex-center h-7_5rem w-7_5rem">
            <SMSBigIcon />
          </div>
          <span className="text-inter-bold-1rem pt-1rem">SMS</span>
        </div>
      </div>
      {showSelectPhoneNumberModal && (
        <SelectPhoneNumbersModal closeModal={handleCloseCreateModal} />
      )}
    </div>
  );
};

export default ChooseMediaView;
