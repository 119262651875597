import * as Yup from 'yup';

export const passwordValidationSchema = Yup.string()
  .min(8, 'Minimum 8 characters')
  .matches(/^(?=.*\d)/, 'Minimum 1 number')
  .matches(/^(?=.*[!@#$%^&*])/, 'Minimum 1 special character')
  .matches(/^(?=.*[a-z])/, 'Minimum 1 lowercase character')
  .matches(/^(?=.*[A-Z])/, 'Minimum 1 uppercase character')
  .required('Password is required');

export const confirmPasswordValidationSchema = Yup.string()
  .oneOf([Yup.ref('password'), undefined], 'Passwords must match')
  .required('Confirm Password is required');

export const firstNameValidationSchema = Yup.string()
  .min(2, 'Minimum 2 characters')
  .required('First Name is required');

export const lastNameValidationSchema = Yup.string()
  .min(2, 'Minimum 2 characters')
  .required('Last Name is required');

export const phoneNumberValidationSchema = Yup.string()
  .matches(/^\d{7,11}$/, 'Invalid phone number')
  .required('Phone number is required');

export const emailWithoutSuffixValidationSchema = Yup.string()
  .min(2, 'Minimum 2 characters')
  .test('no-at-sign', 'Incorrect email', (value) => {
    return !value || value.indexOf('@') === -1;
  })
  .required('Email is required');

export const emailValidationSchema = Yup.string()
  .email('Invalid email')
  .required('Email is required');

export const agreeToPolicyValidationSchema = Yup.boolean()
  .oneOf([true], 'You must agree to the policy to proceed')
  .required('You must agree to the policy to proceed');

export const campaignNameValidationSchema = Yup.string()
  .min(3, 'Minimum 3 characters')
  .required('Campaign Name is required');

export const campaignLinkValidationSchema = Yup.string()
  .required('Campaign Link is required')
  .matches(
    /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/,
    'Invalid URL format. Must start with http:// or https://'
  );

export const campaignDescriptionValidationSchema = Yup.string()
  .min(2, 'Minimum 2 characters')
  .required('Campaign Description is required');

export const countryValidationSchema = Yup.string()
  .min(2, 'Minimum 2 characters')
  .required('Country is required');

export const cityValidationSchema = Yup.string()
  .min(2, 'Minimum 2 characters')
  .required('City is required');

export const companyNameValidationSchema = Yup.string()
  .min(2, 'Minimum 2 characters')
  .required('Company is required');

export const streetValidationSchema = Yup.string()
  .min(2, 'Minimum 2 characters')
  .required('Street is required');
