import React from 'react';
import { SettingsHeaderProps } from './settingsModels';

const SettingsHeader: React.FC<SettingsHeaderProps> = ({
  activeTab,
  setActiveTab,
}) => {
  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <div className="d-flex-row bg-white shadow-box-9 b-radius-0_5rem w-100 h-4_31rem p-1rem mb-2rem">
      <div
        className={`${activeTab === 'agent-bio' ? 'active' : ''} settings-header-tab mr-1rem`}
        onClick={() => handleTabClick('agent-bio')}
      >
        <span>Agent Bio</span>
      </div>
      <div
        className={`${activeTab === 'calendar' ? 'active' : ''} settings-header-tab mr-1rem`}
        onClick={() => handleTabClick('calendar')}
      >
        <span>Calendar</span>
      </div>
      <div
        className={`${activeTab === 'integrations' ? 'active' : ''} settings-header-tab mr-1rem`}
        onClick={() => handleTabClick('integrations')}
      >
        <span>Integrations</span>
      </div>
      <div
        className={`${activeTab === 'payments' ? 'active' : ''} settings-header-tab`}
        onClick={() => handleTabClick('payments')}
      >
        <span>Payment & subscriptions</span>
      </div>
    </div>
  );
};

export default SettingsHeader;
