import React, { useEffect } from 'react';
import { CheckedRoundedIcon } from '../../assets';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { getUser, updateUser } from '../../core/requests/requests';

const ConfirmationPage: React.FC<{ setProgress: (value: number) => void }> = ({
  setProgress,
}) => {
  const navigate = useNavigate();
  const { authLogin, currentUser, setCurrentUser } = useAuth();
  useEffect(() => {
    setProgress(4);
  }, [setProgress]);

  const handleConfirm = async () => {
    try {
      const accessToken = authLogin?.accessToken;
      const userId = authLogin?.userId;

      const updateUserPayload = {
        id: userId ? userId : '',
        email: currentUser?.email ? currentUser?.email : '',
        firstName: currentUser?.firstName ? currentUser?.firstName : '',
        askAdditionalInfo: false,
      };

      if (accessToken && userId) {
        const { data } = await updateUser(
          accessToken,
          userId,
          updateUserPayload
        );

        if (data) {
          const response = await getUser(userId, accessToken);
          if (response.data) {
            setCurrentUser(response.data);
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      navigate('/');
      setProgress(0);
    }
  };

  return (
    <div className="d-flex-start-center mt-8rem w-100 mb-3rem">
      <div className="d-flex-start-start-row w-75 h-100 f-wrap mb-3rem overflow-h">
        <div className="d-flex-start-center w-48 p-2_5rem-3_75rem bg-fffefe b-1 h-100 mw-32rem btl-radius-3rem bbl-radius-3rem">
          <h2 className="h2-primary text-center mb-1rem">
            Are you ready to sell like a pro?
          </h2>
          <p className="roboto-regular-1_125rem p-0-2rem text-center mb-2rem">
            Generate unlimited, sales-proven emails that sound like you and,
            more importantly, sell like you.
          </p>
          <h6 className="inter-semi-bold mb-1rem text-center">
            Astreaux.AI Unlimited
          </h6>
          <div className="d-flex-start-baseline-row mb-2_375rem ">
            <h1 className="price-text">$110</h1>
            <span className="inter-medium">/ per month</span>
          </div>
          <button
            className="btn btn-light-primary w-100 mb-1_625rem"
            onClick={handleConfirm}
          >
            Get Access
          </button>
          <p className="roboto-regular-1rem text-center">
            Your campaign will generate upon successful payment.
          </p>
        </div>
        <div className=" d-flex-start-start w-52 p-2_5rem-4_375rem bg-white h-100 btr-radius-3rem bbr-radius-3rem">
          <h2 className="h2-primary mb-2_375rem">What's included</h2>
          <div className="d-flex-row  mb-2rem">
            <CheckedRoundedIcon />
            <p className="roboto-regular-1_125rem pl-0_5rem">
              100GB user storage
            </p>
          </div>
          <div className="d-flex-row  mb-2rem">
            <CheckedRoundedIcon />
            <p className="roboto-regular-1_125rem pl-0_5rem">
              Custom metrics & timeframes
            </p>
          </div>
          <div className="d-flex-row  mb-2rem">
            <CheckedRoundedIcon />
            <p className="roboto-regular-1_125rem pl-0_5rem">
              Google Sheets integration
            </p>
          </div>
          <div className="d-flex-row  mb-2rem">
            <CheckedRoundedIcon />
            <p className="roboto-regular-1_125rem pl-0_5rem">
              Live chat and email support
            </p>
          </div>
          <div className="d-flex-row  mb-2rem">
            <CheckedRoundedIcon />
            <p className="roboto-regular-1_125rem pl-0_5rem">
              Dedicated Onboarding Specialist
            </p>
          </div>
          <div className="d-flex-row  mb-2rem">
            <CheckedRoundedIcon />
            <p className="roboto-regular-1_125rem pl-0_5rem">
              Dedicated Onboarding Specialist
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPage;
