import React from 'react';

interface InfoModalProps {
  title: string;
  onClose: () => void;
}

const InfoModal: React.FC<InfoModalProps> = ({ title, onClose }) => {
  return (
    <div className="d-flex-center w-100 h-100vh p-absolute top-0 left-0">
      <div className="d-flex-start-start w-20rem z4 bg-white p-1_5rem b-radius-0_5rem">
        <h2 className="text-center ">{title}</h2>
        <div className="w-100 d-flex-center mt-1_5rem">
          <button className="btn btn-header-primary w-5rem" onClick={onClose}>
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default InfoModal;
