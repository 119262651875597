import { CampaignTimeZonesDropdownConfig } from '../config/CampaignsConfig';

export const extractInfoInParentheses = (value: string) => {
  const matches = value.match(/\((.*?)\)/);
  if (matches && matches.length > 1) {
    return `(${matches[1]})`;
  } else {
    return null;
  }
};

export const extractGMTOffset = (str: string): number => {
  const regex = /\((GMT[+-]\d+)\)/;
  const match = str.match(regex);
  if (match && match.length > 1) {
    const offsetStr = match[1].split('GMT')[1];
    return parseInt(offsetStr);
  } else {
    return NaN;
  }
};

export function getGMTStringFromOffset(offset: number): string | null {
  const { timeZones } = CampaignTimeZonesDropdownConfig;
  const targetGMT = `(GMT${offset > 0 ? '+' : ''}${offset})`;

  for (const timeZone of timeZones) {
    if (timeZone.includes(targetGMT)) {
      return targetGMT;
    }
  }

  return null;
}

export const getFormattedStringFromDate = (date: Date | null) => {
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    return '';
  }

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const convertTimeTo12HourFormat = (timeString: string) => {
  const date = new Date(`2000-01-01T${timeString}`);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const period = hours >= 12 ? 'PM' : 'AM';
  const hours12 = hours % 12 || 12;
  const formattedTime = `${hours12}:${minutes.toString().padStart(2, '0')} ${period}`;

  return formattedTime;
};

export function isAM(timeString: string) {
  const date = new Date(`2000-01-01T${timeString}`);
  const hours = date.getHours();

  return hours < 12;
}

export const getFormatTimeWithoutPeriod = (timeString: string) => {
  const date = new Date(`2000-01-01T${timeString}`);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const formattedTime = `${hours % 12 || 12}:${minutes.toString().padStart(2, '0')}`;

  return formattedTime;
};
