import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { IntroductionPNG } from '../../assets';
import { useAuth } from '../../context/AuthContext';

const Introduction: React.FC<{ setProgress: (value: number) => void }> = ({
  setProgress,
}) => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  useEffect(() => {
    setProgress(1);
  }, [setProgress]);

  const handleNavigateToConversationPage = () => {
    navigate('/conversation');
  };

  return (
    <div className="d-flex-row b-radius-3rem w-75 mt-8rem overflow-h bg-white">
      <div className="d-flex-center w-48 p-1rem ">
        <img className="w-65" src={IntroductionPNG} alt="" />
      </div>
      <div className="d-flex-center w-52 p-10rem-4rem bg-white">
        <h2 className="h2-primary mb-1rem text-center">
          Hey there, {currentUser?.firstName}!
        </h2>
        <h4 className="h4-primary mb-1_375rem text-center">
          Welcome to{' '}
          <Link to="/" className="secondary-sized-link fs-1rem">
            Astreaux.AI
          </Link>
        </h4>
        <p className="p-description text-center mb-2rem">
          Discover how our conversational AI can automate and improve your lead
          funnel and grow sales easily from each lead.
        </p>
        <p className="p-description text-center mb-2rem ">
          We first need to learn the tone and way you write so we can have
          fuller conversations.
        </p>
        <button
          className="btn btn-light-primary w-100"
          onClick={handleNavigateToConversationPage}
        >
          Ready to get started?
        </button>
      </div>
    </div>
  );
};

export default Introduction;
