import React, { useState, useEffect, memo } from 'react';
import {
  AvatarIcon,
  HighlightsAIIcon,
  LogoSmallPNG,
  PinIcon,
  SendMessageIcon,
} from '../../../assets';
import { formatDate } from '../../../helpers/dateHelpers';
import { HighlightsChatProps } from './conversationModels';
import { changeChatControl } from '../../../core/requests/requests';
import { ChatMessageProps } from '../../../core/types/_models';
import { FRONT_URL, SOCKET_URL } from '../../../helpers/envProcessor';
import { io, Socket } from 'socket.io-client';
import { useAuth } from '../../../context/AuthContext';

const HighlightsChat: React.FC<HighlightsChatProps> = ({
  chatById,
  fetchChatById,
}) => {
  const { currentUser } = useAuth();
  const [socket, setSocket] = useState<Socket | null>(null);
  const [message, setMessage] = useState<string>('');
  const [messages, setMessages] = useState<ChatMessageProps[]>([]);
  const [avatarURL, setAvatarURL] = useState<string>('');
  const chatId = chatById?.chat?.id;

  useEffect(() => {
    const newSocket = io(SOCKET_URL || '');
    if (newSocket) {
      setSocket(newSocket);
    }
    return () => {
      newSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (socket) {
      // Join the chat room
      socket.emit('joinChat', chatId);

      // Listen for new messages
      socket.on('chatMessages', (chatMessages) => {
        if (chatMessages?.length > 0) {
          setMessages((prevMessages) => {
            const messageIds = new Set(
              prevMessages.map((msg: ChatMessageProps) => msg.id)
            );
            const uniqueMessages = chatMessages.filter(
              (msg: ChatMessageProps) => !messageIds.has(msg.id)
            );
            return [...prevMessages, ...uniqueMessages];
          });
        }
      });
    }
  }, [socket, chatId]);

  useEffect(() => {
    if (chatById?.messages) {
      setMessages(chatById.messages);
    }
  }, [chatById]);

  const handleSendMessage = () => {
    if (socket && message.trim() !== '') {
      // Send message based on control type
      socket.emit('sendMessage', { message });
      setMessage('');
    }
  };

  const handleChangeChatControl = async (control: string) => {
    try {
      if (chatId) {
        const { data } = await changeChatControl(chatId, control);
        if (data) {
          fetchChatById();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (FRONT_URL && currentUser?.avatar) {
      // Force the avatar URL to update with a cache buster
      setAvatarURL(
        `${FRONT_URL}${currentUser.avatar}?timestamp=${new Date().getTime()}`
      );
    }
  }, [currentUser]);

  return (
    <div className="d-flex-start-start w-60 bg-white p-2rem-2_5rem p-relative h-calc-100vh_minus_10rem ">
      <div className="d-flex-start-start w-100 scroll-auto h-calc-100vh_minus_20rem">
        {messages &&
          messages?.map((socketMessage) => (
            <div
              className="d-flex-start-start w-100 pr-1rem p-relative pl-1_75rem mb-1_75rem"
              key={socketMessage?.id}
            >
              <div className="d-flex-center p-absolute t-0_437rem left-0 bg-white h-2_375rem w-2_375rem b-radius-1_125rem">
                {socketMessage?.sender === 'LEAD' && (
                  <div className="d-flex-center w-2rem h-2rem bg-f1f1f5 b-radius-1rem">
                    <AvatarIcon />
                  </div>
                )}
                {socketMessage?.sender === 'BOT' && (
                  <div className="d-flex-center w-2rem h-2rem ">
                    <img alt="" src={LogoSmallPNG} className="w-2rem h-2rem" />
                  </div>
                )}
                {socketMessage?.sender === 'MANAGER' && (
                  <>
                    {currentUser?.avatar ? (
                      <div className="d-flex-center w-2rem h-2rem">
                        <img
                          src={avatarURL}
                          className="w-2rem h-2rem obj-fit-cover b-radius-1rem"
                          alt=""
                        />
                      </div>
                    ) : (
                      <div className="d-flex-center w-2rem h-2rem ">
                        <AvatarIcon />
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="d-flex-start-start bg-light-grey w-100 p-1_25rem b-radius-1_125rem">
                <div className="d-flex-start-between-row  w-100">
                  <span className="header-name-title pb-1rem">
                    {socketMessage?.sender === 'LEAD' &&
                      `${chatById?.chat?.Lead?.firstname} ${chatById?.chat?.Lead?.lastname}`}
                    {socketMessage?.sender === 'BOT' && 'Astreaux'}
                    {socketMessage?.sender === 'MANAGER' &&
                      `${currentUser?.firstName} ${currentUser?.lastName}`}
                  </span>
                  <span className="f-roboto-secondary fs-0_75rem w-5_875rem">
                    {formatDate(socketMessage?.createdAt ?? '')}
                  </span>
                </div>
                <span className="f-roboto-primary fs-0_875rem lh-1_5rem pr-2rem">
                  {socketMessage?.text}
                </span>
              </div>
            </div>
          ))}

        {chatById?.chat?.responseType === 'BOT' ? (
          <div className="d-flex-center w-100 mt-2rem">
            <button
              className="btn-highlights"
              onClick={() => handleChangeChatControl('MANAGER')}
            >
              Would you like to take over?
              <div className="d-flex-center ml-0_5rem">
                <HighlightsAIIcon />
              </div>
            </button>
          </div>
        ) : (
          <div className="d-flex-center w-100 mt-2rem">
            <button
              className="btn-highlights"
              onClick={() => handleChangeChatControl('BOT')}
            >
              Enable AI assistant back?
              <div className="d-flex-center ml-0_5rem">
                <HighlightsAIIcon />
              </div>
            </button>
          </div>
        )}
      </div>
      <div className="d-flex-row w-100 p-absolute bottom-2_25rem pr-5_5rem">
        <div className="d-flex-center w-2rem h-2rem bg-f1f1f5 b-radius-1rem">
          <AvatarIcon />
        </div>
        <div className="d-flex-center bg-light-grey w-100 p-1_25rem-1rem b-radius-0_625rem">
          <div className="d-flex-row bg-white w-100 p-relative">
            <input
              className="w-100 conversation-ai-input"
              placeholder="Write a message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <div className="d-flex-row p-absolute right-1rem">
              <div className="d-flex-center icon-hovered ">
                <PinIcon />
              </div>
              {message && chatById?.chat?.responseType === 'MANAGER' && (
                <div
                  className="d-flex-center ml-1rem icon-hovered"
                  onClick={handleSendMessage}
                >
                  <SendMessageIcon />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(HighlightsChat);
