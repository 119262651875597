import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
} from 'chart.js';

// Register the components with ChartJS
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler
);

const ConversationLinearChart: React.FC = () => {
  const data = {
    labels: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    datasets: [
      {
        label: 'Replies',
        data: [420, 400, 200, 420, 530, 450, 560, 420, 500, 480, 400, 410],
        borderColor: '#85d799',
        backgroundColor: 'rgba(0, 98, 255, 0.2)',
        tension: 0.4, // Smooth the line
        fill: false,
      },
      {
        label: 'Conversations',
        data: [380, 390, 460, 510, 450, 570, 560, 380, 430, 450, 380, 420],
        borderColor: '#0ebff1',
        backgroundColor: 'rgba(61, 213, 152, 0.2)',
        tension: 0.4, // Smooth the line
        fill: false,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allows height customization
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          font: {
            size: 14, // Customize font size
            family: "'Roboto', sans-serif", // Customize font family
          },
          color: '#92929D',
        },
      },
    },
  };

  const chartContainerStyle = {
    height: '500px', // Customize the height
    width: '100%',
  };

  return (
    <div className="d-flex-start-start w-100 bg-white p-1_125rem-1_625rem mt-1_75rem b-radius-1_25rem">
      <div className="d-flex-row-space-between w-100 mb-1rem">
        <h4 className="h4-primary">Conversations</h4>
        <div className="d-flex-row">
          <div className="d-flex-row mr-3rem">
            <div className="d-flex-center w-0_75rem h-0_75rem bg-85d799   b-radius-0_375rem mr-1rem"></div>
            <span className="fs-0_875rem">Replies</span>
          </div>
          <div className="d-flex-row mr-1rem">
            <div className="d-flex-center w-0_75rem h-0_75rem bg-0ebff1 b-radius-0_375rem mr-1rem"></div>
            <span className="fs-0_875rem">Conversations</span>
          </div>
        </div>
      </div>
      <div style={chartContainerStyle}>
        <Line data={data} options={options} className="p-1rem" />
      </div>
    </div>
  );
};

export default ConversationLinearChart;
