import React, { useState } from 'react';
import { TriangleIcon } from '../../../assets';
//import ConversationCard from './ConversationCard';

const ConversationsList: React.FC = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [sortDropdown, setSortDropdown] = useState(false);

  return (
    <div className="d-flex-row-space-between w-100 mb-1_5rem">
      <div className="d-flex-row">
        <div
          className="d-flex-row pointer"
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <span className="f-roboto-secondary fs-1_125rem">Show:</span>
          <div className="d-flex-row  p-relative">
            <span className="f-roboto-m-primary fs-1_125rem pl-0_375rem">
              All campaigns
            </span>
            <div className="d-flex-center pl-0_375rem">
              <TriangleIcon
                className={`${showDropdown ? 'rotate-180deg' : ''} transition-0_5s`}
              />
            </div>
            {showDropdown && (
              <div className="p-absolute top-1_4rem z1 bg-white">
                {/*LeadsHeaderDropdownsConfig.showLeads.map((option, index) => (
              <div
                className="hovered p-0_2rem-0"
                key={index}
                onClick={() => {
                  setLeadsState((prevState) => ({
                    ...prevState,
                    showLeadsDropdown: option,
                  }));
                  setShowDropdown(false);
                }}
              >
                <span className="f-roboto-m-primary fs-1_125rem pl-0_375rem">
                  {option}
                </span>
              </div>
            ))*/}
                <div className="hovered p-0_2rem-0">
                  <span className="f-roboto-m-primary fs-1_125rem pl-0_375rem fw-600">
                    All campaigns
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="d-flex-row">
        <div
          className="d-flex-row-space-between w-12_5rem h-2_375rem bg-white b-radius-0_5rem p-relative pointer"
          onClick={() => setSortDropdown(!sortDropdown)}
        >
          <div className="d-flex-row pl-0_75rem">
            <span className="sort-title">Sort by:</span>
            <div className="dropdown-wrapper">
              <span className="sort-dropdown-item pl-0_375rem">Default</span>
              {sortDropdown && (
                <div className="p-absolute top-2_375rem z1 bg-white w-100 left-0 ">
                  {/*LeadsHeaderDropdownsConfig.sortLeadsBy.map(
                (option, index) => (
                  <div
                    className="d-flex-center-start h-2_375rem bt-1 hovered"
                    key={index}
                    onClick={() => {
                      setLeadsState((prevState) => ({
                        ...prevState,
                        sortLeadsDropdown: option,
                      }));
                      setSortDropdown(false);
                    }}
                  >
                    <span className="sort-dropdown-item pl-0_75rem">
                      {option}
                    </span>
                  </div>
                )
              )*/}
                  <div className="d-flex-center-start h-2_375rem bt-1 hovered">
                    <span className="sort-dropdown-item pl-0_75rem p-0_2rem-0">
                      default
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="d-flex-center w-1_75rem h-100 bl-1-l-gray">
            <TriangleIcon
              className={`${sortDropdown ? 'rotate-180deg' : ''} transition-0_5s`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConversationsList;
