import React from 'react';

import {
  PaginationArrowLeftIcon,
  PaginationArrowRightIcon,
  TriangleSmallIcon,
} from '../../../assets';
import {
  LeadsPaginationProps,
  LeadsFilterStateProps,
} from '../../../core/types/_models';
import { LeadsFilterConfig } from '../../../config/LeadsConfig';

const PaginationComponent: React.FC<LeadsPaginationProps> = ({
  leadsState,
  setLeadsState,
  totalPaginationPages,
  totalLeadsItems,
}) => {
  const currentPage = leadsState?.currentPage || 1;
  const itemsPerPage = leadsState?.itemsPerPage;

  let startItem = 1;
  let endItem = totalLeadsItems;
  let isPageNumberOutOfRange = false;

  if (totalLeadsItems === 0) {
    startItem = 0;
    endItem = 0;
  } else if (totalLeadsItems <= itemsPerPage) {
    endItem = totalLeadsItems;
  } else {
    if (currentPage === 1) {
      endItem = itemsPerPage;
    } else {
      startItem = itemsPerPage * (currentPage - 1) + 1;
      endItem = Math.min(totalLeadsItems, startItem + itemsPerPage - 1);
    }
  }

  const displayRange = `${startItem}-${endItem}`;

  const PageNumbers = [...new Array(totalPaginationPages)].map((_, index) => {
    const pageNumber = index + 1;
    const isPageNumberFirst = pageNumber === 1;
    const isPageNumberLast = pageNumber === totalPaginationPages;
    const isCurrentPageWithinTwoPageNumbers =
      Math.abs(pageNumber - currentPage) <= 2;

    if (
      isPageNumberFirst ||
      isPageNumberLast ||
      isCurrentPageWithinTwoPageNumbers
    ) {
      isPageNumberOutOfRange = false;
      return (
        <li
          key={index}
          className={`pagination-item ${currentPage === index + 1 ? 'active' : ''}`}
          onClick={() => goToPage(index + 1)}
        >
          <div className="pagination-item-container pagination-icon">
            <span>{index + 1}</span>
          </div>
        </li>
      );
    }

    if (!isPageNumberOutOfRange) {
      isPageNumberOutOfRange = true;
      return (
        <li key={index} className="pagination-item">
          <div className="pagination-ellipsis">...</div>
        </li>
      );
    }

    return null;
  });

  const handleIncreaseItemsPerPage = () => {
    const currentIndex = LeadsFilterConfig.itemsPerPage.indexOf(itemsPerPage);
    if (currentIndex < LeadsFilterConfig.itemsPerPage.length - 1) {
      const newItemsPerPage = LeadsFilterConfig.itemsPerPage[currentIndex + 1];
      setLeadsState((prevState: LeadsFilterStateProps) => ({
        ...prevState,
        itemsPerPage: newItemsPerPage,
      }));
    }
  };

  const handleDecreaseItemsPerPage = () => {
    const currentIndex = LeadsFilterConfig.itemsPerPage.indexOf(
      leadsState.itemsPerPage
    );
    if (currentIndex > 0) {
      const newItemsPerPage = LeadsFilterConfig.itemsPerPage[currentIndex - 1];
      setLeadsState((prevState: LeadsFilterStateProps) => ({
        ...prevState,
        itemsPerPage: newItemsPerPage,
      }));
    }
  };

  const goToPage = (pageNumber: number) => {
    setLeadsState((prevState: LeadsFilterStateProps) => ({
      ...prevState,
      currentPage: pageNumber,
    }));
  };

  const goToNextPage = () => {
    if (currentPage < totalPaginationPages) {
      goToPage(currentPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      goToPage(currentPage - 1);
    }
  };

  return (
    <div className="d-flex-row-space-between w-100 pb-2_875rem pr-0_625rem">
      <span className="pl-1_125rem f-roboto-m-primary fs-0_875rem ">
        {displayRange} of {totalLeadsItems} items
      </span>
      <ul className="d-flex-row-space-between list-style-none">
        <li className="pagination-item" onClick={goToPreviousPage}>
          <button
            className="pagination-item-container pagination-icon"
            disabled={currentPage === 1}
          >
            <PaginationArrowLeftIcon />
          </button>
        </li>
        <div className="d-flex-row p-0-1rem">{PageNumbers}</div>
        <li className="pagination-item" onClick={goToNextPage}>
          <button className="pagination-item-container pagination-icon">
            <PaginationArrowRightIcon className="" />
          </button>
        </li>
        <div className="d-flex-row ml-1rem h-2_375rem b-1 b-radius-0_625rem ">
          <span className="p-0-0_625rem f-roboto-secondary fs-0_875rem">
            {itemsPerPage}
          </span>
          <div className="d-flex-center bl-1 h-2_375rem w-1_5rem">
            <div
              className="d-flex-end-center w-100 h-100 icon-hovered"
              onClick={handleIncreaseItemsPerPage}
            >
              <TriangleSmallIcon className="t-rotate-180 m-0_188rem" />
            </div>
            <div
              className="d-flex-start-center w-100 h-100 icon-hovered"
              onClick={handleDecreaseItemsPerPage}
            >
              <TriangleSmallIcon className="m-0_188rem " />
            </div>
          </div>
        </div>
      </ul>
    </div>
  );
};

export default PaginationComponent;
