import React, { createContext, useState, useContext, ReactNode } from 'react';
import {
  SidebarProps,
  LeadsListProps,
  EmailMessageProps,
  CampaignDataProps,
} from '../core/types/_models';
import { campaignViewValues } from '../config/CampaignsConfig';

interface AppContextTypes {
  sidebarState: SidebarProps['sidebarState'];
  setSidebarState: React.Dispatch<
    React.SetStateAction<SidebarProps['sidebarState']>
  >;
  leadsList: LeadsListProps[] | null;
  setLeadsList: React.Dispatch<React.SetStateAction<LeadsListProps[] | null>>;
  currentStartCampaignView: string;
  setCurrentStartCampaignView: React.Dispatch<React.SetStateAction<string>>;
  showModalBackground: boolean;
  setShowModalBackground: React.Dispatch<React.SetStateAction<boolean>>;
  currentCampaign: CampaignDataProps | null;
  setCurrentCampaign: React.Dispatch<
    React.SetStateAction<CampaignDataProps | null>
  >;
  chosenConversation: EmailMessageProps | null;
  setChosenConversation: React.Dispatch<
    React.SetStateAction<EmailMessageProps | null>
  >;
}

export const AppContext = createContext<AppContextTypes>({
  sidebarState: 'visible',
  setSidebarState: () => {},
  leadsList: [],
  setLeadsList: () => {},
  currentStartCampaignView: campaignViewValues?.settings,
  setCurrentStartCampaignView: () => {},
  showModalBackground: false,
  setShowModalBackground: () => {},
  currentCampaign: null,
  setCurrentCampaign: () => {},
  chosenConversation: null,
  setChosenConversation: () => {},
});

export const AppProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [sidebarState, setSidebarState] =
    useState<SidebarProps['sidebarState']>('minimized');
  const [leadsList, setLeadsList] = useState<LeadsListProps[] | null>([]);
  const [currentStartCampaignView, setCurrentStartCampaignView] =
    useState<string>(campaignViewValues?.settings);
  const [currentCampaign, setCurrentCampaign] =
    useState<CampaignDataProps | null>(null);
  const [showModalBackground, setShowModalBackground] =
    useState<boolean>(false);
  const [chosenConversation, setChosenConversation] =
    useState<EmailMessageProps | null>(null);

  const AppContextValue: AppContextTypes = {
    sidebarState,
    setSidebarState,
    leadsList,
    setLeadsList,
    currentStartCampaignView,
    setCurrentStartCampaignView,
    showModalBackground,
    setShowModalBackground,
    currentCampaign,
    setCurrentCampaign,
    chosenConversation,
    setChosenConversation,
  };

  return (
    <AppContext.Provider value={AppContextValue}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppData = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppData must be used within an AppProvider');
  }
  return context;
};
