import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import {
  ForgotPasswordPNG,
  CheckedIcon,
  ChooseNewPasswordPNG,
  ResetCompletedPNG,
} from '../../assets';
import {
  confirmPasswordValidationSchema,
  passwordValidationSchema,
} from './components/ValidationSchemaItems';
import { passwordValidationRequirements } from '../../config/authConfig';
import { resetPassword } from '../../core/requests/requests';
import { useAuth } from '../../context/AuthContext';
import { maskEmail } from '../../helpers/authHelpers';

const ResetPassword: React.FC = () => {
  const { emailToReset, setEmailToReset } = useAuth();
  const [sendForm, setSendForm] = useState<boolean | null>(null);
  const [focusedFields, setFocusedFields] = useState({
    password: false,
    confirmPassword: false,
  });

  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  const validationSchema = Yup.object().shape({
    password: passwordValidationSchema,
    confirmPassword: confirmPasswordValidationSchema,
  });

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const resetToken = urlParams.get('token');

  const handleFieldFocus = (fieldName: string) => {
    setFocusedFields((prevFocusedFields) => ({
      ...prevFocusedFields,
      [fieldName]: true,
    }));
  };

  const handleFieldBlur = (fieldName: string) => {
    setFocusedFields((prevFocusedFields) => ({
      ...prevFocusedFields,
      [fieldName]: false,
    }));
  };

  const handleSubmit = async (values: { password: string }) => {
    try {
      if (resetToken) {
        const { data } = await resetPassword(resetToken, values?.password);
        if (data) {
          setSendForm(data);
        }
      }
    } catch {
      console.log('Reset password failed');
    }
  };

  const handleClearEmailToReset = () => {
    setEmailToReset(null);
  };

  return (
    <div className="d-flex-center size-max">
      <img alt="" src={ForgotPasswordPNG} className="login-background z-1" />
      <div className="forgot-password-block d-flex-center-start w-65 max-w-70rem bg-white p-relative z3">
        {!sendForm ? (
          <div className="d-flex-start-start w-100 ">
            <div className="d-flex-start-start w-60">
              <h2 className="h2-primary mb-0_75rem">Choose new password</h2>
              <p className="p-description mb-1_375rem w-85">
                Almost done. Enter your new password and youre all set.
              </p>

              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, isValid }) => (
                  <Form className="w-100">
                    <div className="d-flex-row form-row mb-1_5rem">
                      <div className="form-item  w-100">
                        <Field
                          type="password"
                          name="password"
                          className={`form-control ${errors.password ? 'error-border' : ''} `}
                          placeholder="Create password"
                          onFocus={() => handleFieldFocus('password')}
                          onBlur={() => handleFieldBlur('password')}
                        />
                        {errors.password && (
                          <div className="form-error">{errors.password}</div>
                        )}
                        {focusedFields.password && (
                          <div className="d-flex-center title-container">
                            <span
                              className={`form-title ${errors.password ? 'error-title' : 'correct-title'}`}
                            >
                              Create Password
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-flex-row form-row mb-1_875rem">
                      <div className="form-item  w-100">
                        <Field
                          type="password"
                          name="confirmPassword"
                          className={`form-control ${errors.confirmPassword ? 'error-border' : ''} `}
                          placeholder="Confirm Password"
                          onFocus={() => handleFieldFocus('confirmPassword')}
                          onBlur={() => handleFieldBlur('confirmPassword')}
                        />
                        {errors.confirmPassword && (
                          <div className="form-error">
                            {errors.confirmPassword}
                          </div>
                        )}
                        {focusedFields.confirmPassword && (
                          <div className="d-flex-center title-container">
                            <span
                              className={`form-title ${errors.confirmPassword ? 'error-title' : 'correct-title'}`}
                            >
                              Confirm Password
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-flex-row p-1_5rem-0 f-wrap mb-1_875rem">
                      <div className="d-flex-row p-1_5rem-0 f-wrap">
                        {passwordValidationRequirements.map(
                          (requirement, index) => (
                            <div
                              key={index}
                              className="d-flex-row w-13rem p-0_2rem-0"
                            >
                              <CheckedIcon />
                              <span className="checked-text pl-0_5rem ">
                                {requirement}
                              </span>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary w-100"
                      disabled={!isValid}
                    >
                      Reset Password
                    </button>
                  </Form>
                )}
              </Formik>
              <img
                alt=""
                src={ChooseNewPasswordPNG}
                className="w-40 p-absolute mw-15rem right-_-4rem bottom-_-4rem "
              />
            </div>
          </div>
        ) : (
          <div className="d-flex-start-start w-100 ">
            <div className="d-flex-start-start w-60">
              <h2 className="h2-primary mb-0_75rem">Reset complete</h2>
              <p className="p-description  w-85">
                All done! We have sent an email to{' '}
                {emailToReset ? maskEmail(emailToReset) : ''} to confirm
              </p>
              <button
                type="submit"
                className="btn btn-primary w-100 mt-2rem"
                onClick={() => setSendForm(null)}
              >
                Reset Password
              </button>
            </div>
            <img
              alt=""
              src={ResetCompletedPNG}
              className="w-35 p-absolute mw-15rem right-_-4rem  bottom-_-4rem "
            />
          </div>
        )}
        <div className="d-flex-start-start p-absolute left-5rem bottom-_-3rem bg-white h-4rem bbl-radius-1_25rem bbr-radius-1_25rem w-50">
          <div className="w-100 h-1rem shadow-box-bottom z1"></div>
          <div className="d-flex-center w-100 h-3rem z1">
            <Link
              to="/"
              className="primary-link"
              onClick={handleClearEmailToReset}
            >
              Already have an Square account? Log in
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
