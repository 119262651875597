import React, { ReactNode, useEffect, useState } from 'react';
import { Header, Sidebar } from './components';
import { useAppData } from '../context/AppContext';

interface LayoutBuilderProps {
  children: ReactNode;
}

const LayoutBuilder: React.FC<LayoutBuilderProps> = ({ children }) => {
  const { showModalBackground } = useAppData();
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    if (!showModalBackground) {
      // Reset scroll position when modal background is not shown
      window.scrollTo(0, scrollPosition);
    } else {
      // Save scroll position when modal background is shown
      const currentScroll = window.scrollY;
      setScrollPosition(currentScroll);

      // Disable scroll and keep position
      const body = document.body;
      body.style.position = 'fixed';
      body.style.top = `-${currentScroll}px`;
      body.style.width = '100%';
      body.style.overflow = 'hidden';

      // Clean-up function to restore scroll position and styles
      return () => {
        body.style.position = '';
        body.style.top = '';
        body.style.width = '';
        body.style.overflow = '';
        window.scrollTo(0, currentScroll);
      };
    }
  }, [showModalBackground]);

  return (
    <div className="d-flex-start-start p-relative w-100">
      <Header />
      <Sidebar />
      <div className="pt-4_375rem pl-4rem w-100">{children}</div>
      {showModalBackground && <div className="modal"></div>}
    </div>
  );
};

export default LayoutBuilder;
