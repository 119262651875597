import React from 'react';

const DynamicHeader: React.FC<{ progress: number }> = ({ progress }) => {
  return (
    <div className="d-flex-center w-100 pt-3_25rem p-absolute top-0">
      <div className="d-flex-start-between-row w-75 h-2rem">
        <div className="d-flex-center-start w-21 p-relative">
          <div className="progress-bar mb-0_75rem "></div>
          <div
            className="progress-bar-active mb-0_75rem p-absolute top-0"
            style={{
              width: progress > 1 ? '100%' : progress === 1 ? '50%' : '0%',
              transition: 'width 0.5s',
            }}
          ></div>
          <span
            className={`${progress >= 1 ? 'dynamic-header-active-text' : 'dynamic-header-text'}`}
          >
            1. Introduction
          </span>
        </div>
        <div className="d-flex-center-start w-21 p-relative">
          <div className="progress-bar mb-0_75rem"></div>
          <div
            className="progress-bar-active mb-0_75rem p-absolute top-0"
            style={{
              width: progress > 2 ? '100%' : progress === 2 ? '50%' : '0%',
              transition: 'width 0.5s',
            }}
          ></div>
          <span
            className={`${progress >= 2 ? 'dynamic-header-active-text' : 'dynamic-header-text'}`}
          >
            2. Conversation
          </span>
        </div>
        <div className="d-flex-center-start w-21 p-relative">
          <div className="progress-bar mb-0_75rem"></div>
          <div
            className="progress-bar-active mb-0_75rem p-absolute top-0"
            style={{
              width: progress > 3 ? '100%' : progress === 3 ? '50%' : '0%',
              transition: 'width 0.5s',
            }}
          ></div>
          <span
            className={`${progress >= 3 ? 'dynamic-header-active-text' : 'dynamic-header-text'}`}
          >
            3. More About You
          </span>
        </div>
        <div className="d-flex-center-start w-21 p-relative">
          <div className="progress-bar mb-0_75rem"></div>
          <div
            className="progress-bar-active mb-0_75rem p-absolute top-0"
            style={{
              width: progress > 4 ? '100%' : progress === 4 ? '50%' : '0%',
              transition: 'width 0.5s',
            }}
          ></div>
          <span
            className={`${progress >= 4 ? 'dynamic-header-active-text' : 'dynamic-header-text'}`}
          >
            4. Confirmation
          </span>
        </div>
      </div>
    </div>
  );
};

export default DynamicHeader;
