interface DashboardTemporaryDataProps {
  id: string;
  name: string;
  email: string;
  phone: string;
  leadType: string;
  consultationStatus: string;
  dealStatus: string;
}

export const DashboardTemporaryData: DashboardTemporaryDataProps[] = [
  {
    id: '1',
    name: 'John Doe',
    email: 'jdoe@me.com',
    phone: '(671) 555-0110',
    leadType: 'Warm',
    consultationStatus: 'Scheduled',
    dealStatus: 'closed',
  },
  {
    id: '2',
    name: 'Guy Hawkins',
    email: 'dolores.chambers@example.com',
    phone: '(205) 555-0100',
    leadType: 'Warm',
    consultationStatus: 'Scheduled',
    dealStatus: 'closed',
  },
  {
    id: '3',
    name: 'David Smith',
    email: 'jdoe@me.com',
    phone: '123-456-7890',
    leadType: 'Warm',
    consultationStatus: 'Scheduled',
    dealStatus: 'closed',
  },
  {
    id: '4',
    name: 'Sarah Johnson',
    email: 'jdoe@me.com',
    phone: '123-456-7890',
    leadType: 'Warm',
    consultationStatus: 'Scheduled',
    dealStatus: 'closed',
  },
  {
    id: '5',
    name: 'Michael Brown',
    email: 'jdoe@me.com',
    phone: '123-456-7890',
    leadType: 'Warm',
    consultationStatus: 'Scheduled',
    dealStatus: 'closed',
  },
];
