import React from 'react';
import { CrossIcon } from '../../../assets';

const CustomView: React.FC = () => {
  return (
    <div className="d-flex-start-start-row pt-1_75rem w-100 mb-1_5rem">
      <div className="d-flex-start-start w-65">
        <span className="text-inter-medium pb-0_5rem">Initial Message</span>
        <div className="d-flex-row w-100">
          <div className="d-flex-row mr-0_625rem w-100 p-relative">
            <input
              placeholder="Input text"
              className="campaign-convert-input "
            />
            <div className="d-flex icon-hovered p-absolute right-1rem">
              <CrossIcon />
            </div>
          </div>
          <button className="btn-convert-secondary mr-0_625rem">
            + Add Dialog
          </button>
          <button className="btn-convert-primary mr-1_5rem">Start</button>
        </div>
      </div>
      <div className="d-flex-start-start w-35 pt-1_75rem pl-1_75rem bg-header h-100 b-radius-0_5rem">
        <div className="d-flex-center b-radius-0_5rem p-2_625rem bg-white w-100 b-1">
          <button className="btn-text-preview-primary">
            Input text preview
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomView;
