import React, { memo } from 'react';
import { AppRoutes } from './routes';
import { AppProvider, AuthProvider } from './context';

const App: React.FC = () => {
  return (
    <div className="App">
      <AuthProvider>
        <AppProvider>
          <AppRoutes />
        </AppProvider>
      </AuthProvider>
    </div>
  );
};

export default memo(App);
