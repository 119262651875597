import React from 'react';
import {
  GoogleIcon,
  TikTokIcon,
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
} from '../../../assets';
import { useNavigate } from 'react-router-dom';

const SocialMediaConnections: React.FC = () => {
  const navigate = useNavigate();

  const handleSocialMediaConnection = () => {
    navigate('/error-404');
  };
  return (
    <div className="d-flex-start-start w-100 mb-2rem">
      <h3 className="h3-primary mb-3rem ">
        Connect Astreaux.AI to your social media accounts
      </h3>
      <div className="d-flex-start-between-row w-100">
        <div className="d-flex-start-start w-calc-20_minus_1_5rem">
          <div className="d-flex-row w-100 h-2_5rem mb-2_375rem">
            <GoogleIcon />
            <span className="pl-1_125rem">Google</span>
          </div>
          <button
            className="btn btn-secondary w-100"
            onClick={handleSocialMediaConnection}
          >
            Connect
          </button>
        </div>
        <div className="d-flex-start-start w-calc-20_minus_1_5rem">
          <div className="d-flex-row w-100 h-2_5rem mb-2_375rem">
            <TikTokIcon />
            <span className="pl-1_125rem">TikTok</span>
          </div>
          <button
            className="btn btn-secondary w-100"
            onClick={handleSocialMediaConnection}
          >
            Connect
          </button>
        </div>
        <div className="d-flex-start-start w-calc-20_minus_1_5rem">
          <div className="d-flex-row w-100 h-2_5rem mb-2_375rem">
            <FacebookIcon />
            <span className="pl-1_125rem">Facebook</span>
          </div>
          <button
            className="btn btn-secondary w-100"
            onClick={handleSocialMediaConnection}
          >
            Connect
          </button>
        </div>
        <div className="d-flex-start-start w-calc-20_minus_1_5rem">
          <div className="d-flex-row w-100 h-2_5rem mb-2_375rem">
            <InstagramIcon />
            <span className="pl-1_125rem">Instagram</span>
          </div>
          <button
            className="btn btn-secondary w-100"
            onClick={handleSocialMediaConnection}
          >
            Connect
          </button>
        </div>
        <div className="d-flex-start-start w-calc-20_minus_1_5rem">
          <div className="d-flex-row w-100 h-2_5rem mb-2_375rem">
            <TwitterIcon />
            <span className="pl-1_125rem">Twitter</span>
          </div>
          <button
            className="btn btn-secondary w-100"
            onClick={handleSocialMediaConnection}
          >
            Connect
          </button>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaConnections;
