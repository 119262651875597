import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  Login,
  Registration,
  ForgotPassword,
  ResetPassword,
} from '../pages/auth';
import NotFound from '../pages/errors/NotFound';
import { AuthBackgroundPNG } from '../assets';

const AuthRoutes: React.FC = () => {
  return (
    <div className="d-flex-start-center-row size-max p-relative bg-2b1943">
      <img
        className="p-absolute size-max object-fit-cover z0"
        alt=""
        src={AuthBackgroundPNG}
      />
      <Routes>
        <Route index element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/error-404" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default AuthRoutes;
