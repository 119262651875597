import React, { useRef, useEffect, useState } from 'react';
import { AvatarIcon, EditAvatarIcon, TrashIcon } from '../../../assets';
import { useAuth } from '../../../context/AuthContext';
import {
  getUser,
  removeAvatar,
  uploadAvatar,
} from '../../../core/requests/requests';
import { FRONT_URL } from '../../../helpers/envProcessor';

const ProfileHeader: React.FC = () => {
  const { authLogin, currentUser, setCurrentUser } = useAuth();
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [avatarURL, setAvatarURL] = useState<string>('');
  const userId = currentUser?.id;
  const token = authLogin?.accessToken;

  const handleClick = () => {
    inputFileRef.current?.click();
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file && userId) {
      try {
        const response = await uploadAvatar(userId, file);
        if (response && token) {
          const { data } = await getUser(userId, token);
          if (data) {
            setCurrentUser(data);
            // Force the avatar URL to update with a cache buster
            setAvatarURL(
              `${FRONT_URL}${data.avatar}?timestamp=${new Date().getTime()}`
            );
          }
        }
      } catch (error) {
        console.error('Error uploading avatar', error);
      }
    }
  };

  const handleDeleteAvatar = async () => {
    if (userId && token) {
      try {
        const response = await removeAvatar(userId);
        if (response && token) {
          const { data } = await getUser(userId, token);
          if (data) {
            setCurrentUser(data);
            setAvatarURL('');
          }
        }
      } catch (error) {
        console.error('Error deleting avatar', error);
      }
    }
  };

  useEffect(() => {
    if (FRONT_URL && currentUser?.avatar) {
      // Force the avatar URL to update with a cache buster
      setAvatarURL(
        `${FRONT_URL}${currentUser.avatar}?timestamp=${new Date().getTime()}`
      );
    }
  }, [currentUser]);

  return (
    <div className="d-flex-row-space-between w-100">
      <div className="d-flex-row mb-2rem">
        <div className="d-flex-center w-7rem h-7rem p-relative mr-1rem">
          <div className="d-flex-center b-radius-3_5rem overflow-h ">
            {currentUser?.avatar ? (
              <img
                src={avatarURL}
                className="w-7rem h-7rem obj-fit-cover"
                alt=""
              />
            ) : (
              <AvatarIcon className="w-7rem h-7rem" />
            )}
          </div>
          <div
            className="d-flex-center p-absolute top-0 right-0 icon-hovered w-2rem h-2rem bg-white b-radius-1rem"
            onClick={handleDeleteAvatar}
          >
            <TrashIcon className="p-0_375rem" />
          </div>
          <div
            className="d-flex-center p-absolute bottom-0 right-0 icon-hovered w-2rem h-2rem bg-white b-radius-1rem"
            onClick={handleClick}
          >
            <EditAvatarIcon className="p-0_375rem" />
            <input
              type="file"
              ref={inputFileRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
          </div>
        </div>
        <div className="d-flex-start-start">
          <h3 className="h3-primary mb-1rem ">
            {currentUser?.firstName} {currentUser?.lastName}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
