import React from 'react';
import {
  AddIcon,
  CalendarWithDateIcon,
  ChatAvatarIcon,
  PreviousCampaignIcon,
  RoundedPlusIcon,
} from '../../../assets';
import { ReminderViewProps } from './startCampaignModels';
import {
  campaignMessageTypesConfig,
  campaignViewValues,
} from '../../../config/CampaignsConfig';
import { useAppData } from '../../../context/AppContext';

const ReminderView: React.FC<ReminderViewProps> = ({
  setCurrentMessageType,
}) => {
  const { setCurrentStartCampaignView } = useAppData();
  const handleChangeView = () => {
    setCurrentMessageType(campaignMessageTypesConfig?.reminderMessageType);
    setCurrentStartCampaignView(campaignViewValues?.chooseMedia);
  };
  return (
    <div className="d-flex-start-start pb-1_5rem w-100">
      <div className="d-flex-start-start-row p-1rem-0 w-100 ">
        <div className="d-flex-start-start w-55 mr-1_75rem">
          <div className="d-flex-row-space-between w-100 bg-header h-2_375rem b-radius-0_5rem p-0-0_75rem">
            <div className="d-flex-row">
              <PreviousCampaignIcon />
              <span className="text-inter-medium pl-0_625rem pr-0_5rem">
                Previous campaigns
              </span>
              <div className="d-flex-center bg-badge p-0_375rem b-radius-0_25rem">
                <span className="abril-fatface-regular">3</span>
              </div>
            </div>
            <div className="d-flex-row">
              <div className="d-flex-center icon-hovered mr-0_375rem">
                <RoundedPlusIcon />
              </div>
              <div className="d-flex-center icon-hovered">
                <AddIcon />
              </div>
            </div>
          </div>
          <div className="d-flex-row-space-between p-0-1rem h-3_5rem mt-0_75rem b-radius-0_875rem b-1 w-100 ">
            <div className="d-flex-row ">
              <input type="checkbox" className="checkbox-rounded mr-0_5rem" />
              <span className="text-inter-regular-1rem">
                First Property Viewing
              </span>
            </div>
            <div className="d-flex-row ">
              <div className="d-flex-center mr-0_375rem">
                <CalendarWithDateIcon />
              </div>
              <span className="text-inter-regular-secondary pr-1_25rem">
                Mar 23
              </span>
              <div className="d-flex-center icon-hovered">
                <AddIcon />
              </div>
            </div>
          </div>
          <div className="d-flex-row-space-between p-0-1rem h-3_5rem mt-0_75rem b-radius-0_875rem b-1 w-100 ">
            <div className="d-flex-row ">
              <input type="checkbox" className="checkbox-rounded mr-0_5rem" />
              <span className="text-inter-regular-1rem">
                Second Property Viewing
              </span>
            </div>
            <div className="d-flex-row ">
              <div className="d-flex-center mr-0_375rem">
                <CalendarWithDateIcon />
              </div>
              <span className="text-inter-regular-secondary pr-1_25rem">
                Mar 23
              </span>
              <div className="d-flex-center icon-hovered">
                <AddIcon />
              </div>
            </div>
          </div>
          <div className="d-flex-row-space-between p-0-1rem h-3_5rem mt-0_75rem b-radius-0_875rem b-1 w-100 ">
            <div className="d-flex-row ">
              <input type="checkbox" className="checkbox-rounded mr-0_5rem" />
              <span className="text-inter-regular-1rem">
                First Time Homebuyers
              </span>
            </div>
            <div className="d-flex-row ">
              <div className="d-flex-center mr-0_375rem">
                <CalendarWithDateIcon />
              </div>
              <span className="text-inter-regular-secondary pr-1_25rem">
                Mar 23
              </span>
              <div className="d-flex-center icon-hovered">
                <AddIcon />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex-start-start w-45 bg-header b-radius-0_625rem pt-1_75rem pl-1_75rem p-relative">
          <div className="d-flex-start-start bg-white btl-radius-1_125rem bl-1 bt-1 w-100 pt-2_5rem pl-2_5rem pb-1rem">
            <div className="d-flex-start-start-row  w-100">
              <div className="d-flex-center bg-primary-message p-0_75rem mr-0_75rem btl-radius-0_875rem btr-radius-0_875rem bbl-radius-0_875rem ">
                <span className="text-message-inter-regular">
                  Et ipsum tincidunt justo nunc. Porttitor non enim id
                  scelerisque cursus et vel et. Etiam dictum congue adipiscing
                  commodo nunc. Aliquet nunc dignissim in pellentesque nisi
                  donec eu nunc.
                </span>
              </div>
              <div className="d-flex-center mr-0_5rem">
                <ChatAvatarIcon />
              </div>
            </div>
          </div>
          <div className="gradient-overlay"></div>
        </div>
      </div>
      <div className="d-flex-start-start w-100">
        <span className="text-inter-medium pb-0_5rem">Initial Message</span>
        <div className="d-flex-row w-100 pb-2_5rem bb-1">
          <input
            placeholder="Enter the first message"
            className="campaign-convert-input mr-1rem w-100"
          />
          <button className="btn-convert-primary" onClick={handleChangeView}>
            Start
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReminderView;
