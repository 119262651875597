import React from 'react';
import { AgentSelectionModalProps } from './startCampaignModels';
import { CloseIcon, SearchIcon } from '../../../assets';

interface AgentSelectionProps {
  id: number;
  name: string;
  email: string;
  title: string;
  phone: string;
}

const AgentSelectionModal: React.FC<AgentSelectionModalProps> = ({
  onClose,
  onConfirm,
}) => {
  const agentSelectionData: AgentSelectionProps[] = [
    {
      id: 1,
      name: 'John Doe',
      title: 'Account Manager',
      email: 'jDn1I@example.com',
      phone: '123-456-7890',
    },
    {
      id: 2,
      name: 'Jane Doe',
      title: 'Account Manager',
      email: 'jDn1I@example.com',
      phone: '123-456-7890',
    },
    {
      id: 3,
      name: 'John Doe',
      title: 'Account Manager',
      email: 'jDn1I@example.com',
      phone: '123-456-7890',
    },
    {
      id: 4,
      name: 'Jane Doe',
      title: 'Account Manager',
      email: 'jDn1I@example.com',
      phone: '123-456-7890',
    },
    {
      id: 5,
      name: 'John Doe',
      title: 'Account Manager',
      email: 'jDn1I@example.com',
      phone: '123-456-7890',
    },
    {
      id: 6,
      name: 'Jane Doe',
      title: 'Account Manager',
      email: 'jDn1I@example.com',
      phone: '123-456-7890',
    },
    {
      id: 7,
      name: 'John Doe',
      title: 'Account Manager',
      email: 'jDn1I@example.com',
      phone: '123-456-7890',
    },
    {
      id: 8,
      name: 'Jane Doe',
      title: 'Account Manager',
      email: 'jDn1I@example.com',
      phone: '123-456-7890',
    },
    {
      id: 9,
      name: 'John Doe',
      title: 'Account Manager',
      email: 'jDn1I@example.com',
      phone: '123-456-7890',
    },
    {
      id: 10,
      name: 'Jane Doe',
      title: 'Account Manager',
      email: 'jDn1I@example.com',
      phone: '123-456-7890',
    },
    {
      id: 11,
      name: 'John Doe',
      title: 'Account Manager',
      email: 'jDn1I@example.com',
      phone: '123-456-7890',
    },
    {
      id: 12,
      name: 'Jane Doe',
      title: 'Account Manager',
      email: 'jDn1I@example.com',
      phone: '123-456-7890',
    },
    {
      id: 13,
      name: 'John Doe',
      title: 'Account Manager',
      email: 'jDn1I@example.com',
      phone: '123-456-7890',
    },
  ];

  return (
    <div className="d-flex-center w-100 h-100vh p-absolute top-0 left-0">
      <div className="d-flex-start-between w-50rem h-80vh z4 bg-white  b-radius-0_5rem">
        <div className="d-flex-row-space-between w-100 p-1rem-1_5rem bb-1-l-gray">
          <span className="ff-poppins fw-500 fs-0_875rem c-regular">
            Select Agents:
          </span>
          <div className="d-flex-center icon-hovered" onClick={onClose}>
            <CloseIcon />
          </div>
        </div>
        <div className="d-flex-start-start w-100 h-calc-100-8rem">
          <div className="d-flex-center w-100 p-1rem-1_5rem ">
            <div className="d-flex-row w-100 p-relative">
              <input
                className="settings-modal-input"
                placeholder="Search for Agents"
              />
              <div className="d-flex-center p-absolute top-0_8rem left-1rem">
                <SearchIcon />
              </div>
            </div>
          </div>
          <div className="d-flex-start-start w-100 pl-1_125rem overflow-auto">
            <table className="agents-modal-table">
              <thead>
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Title</th>
                  <th>Phone</th>
                </tr>
              </thead>
              <tbody>
                {agentSelectionData.map((agent) => (
                  <tr key={agent?.id}>
                    <td>
                      <input type="checkbox" className="modal-checkbox" />
                    </td>
                    <td>{agent.name}</td>
                    <td>{agent.email}</td>
                    <td>{agent.title}</td>
                    <td>{agent.phone}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="d-flex-row-space-between w-100 p-1rem-1_5rem shadow-box-10">
          <button
            className="btn-cancel w-calc-50_minus_0_25rem"
            onClick={onClose}
          >
            <span>Cancel</span>
          </button>
          <button className="btn btn-primary w-calc-50_minus_0_25rem h-2_5rem">
            <span className="ff-inter fw-500 fs-0_875rem">Confirm</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AgentSelectionModal;
