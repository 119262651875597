import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  ReactNode,
} from 'react';
import { AuthProps, UserProfileProps } from '../core/types/_models';

interface AuthContextType {
  authLogin: AuthProps | null;
  setAuthLogin: React.Dispatch<React.SetStateAction<AuthProps | null>>;
  currentUser: UserProfileProps | null;
  setCurrentUser: React.Dispatch<React.SetStateAction<UserProfileProps | null>>;
  hubSpotAuthCode: string | null;
  setHubSpotAuthCode: React.Dispatch<React.SetStateAction<string | null>>;
  emailToReset: string | null;
  setEmailToReset: React.Dispatch<React.SetStateAction<string | null>>;
}

export const AuthContext = createContext<AuthContextType>({
  authLogin: null,
  setAuthLogin: () => {},
  currentUser: null,
  setCurrentUser: () => {},
  hubSpotAuthCode: null,
  setHubSpotAuthCode: () => {},
  emailToReset: null,
  setEmailToReset: () => {},
});

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const storedAuthLogin = localStorage.getItem('authLogin');
  const storedCurrentUser = localStorage.getItem('currentUser');
  const [authLogin, setAuthLogin] = useState<AuthProps | null>(
    storedAuthLogin ? JSON.parse(storedAuthLogin) : null
  );
  const [currentUser, setCurrentUser] = useState<UserProfileProps | null>(
    storedCurrentUser ? JSON.parse(storedCurrentUser) : null
  );
  const [emailToReset, setEmailToReset] = useState<string | null>(null);
  const [hubSpotAuthCode, setHubSpotAuthCode] = useState<string | null>(null);

  useEffect(() => {
    localStorage.setItem('authLogin', JSON.stringify(authLogin));
  }, [authLogin]);

  useEffect(() => {
    localStorage.setItem('currentUser', JSON.stringify(currentUser));
  }, [currentUser]);

  const authContextValue: AuthContextType = {
    authLogin,
    setAuthLogin,
    currentUser,
    setCurrentUser,
    hubSpotAuthCode,
    setHubSpotAuthCode,
    emailToReset,
    setEmailToReset,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
