import React from 'react';

const Support: React.FC = () => {
  return (
    <div className="d-flex-center size-max p-1_875rem p-1_875rem">
      <h1 className="auth-title mb-1_25rem">Support</h1>
    </div>
  );
};

export default Support;
