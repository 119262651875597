import React, { useState } from 'react';
import { TriangleIcon } from '../../../assets';
import { DashboardDropdownsConfig } from '../../../config/dashboardConfig';
//import { DateRangeSelector } from '../../startCampaign/components';

const DashboardHeader: React.FC = () => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [dropdownState, setDropdownState] = useState<string>('This year');
  /*const [isDateRangeSelectorOpen, setIsDateRangeSelectorOpen] = useState(false);
  const [formikValues, setFormikValues] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: '',
    endDate: '',
  });

  const handleCloseDateRangeSelector = () => {
    setIsDateRangeSelectorOpen(false);
  };*/

  return (
    <div className="d-flex-start-baseline-row p-relative">
      <h3 className="ff-poppins fw-600 c-regular fs-1_5rem lh-2_25rem pr-1_375rem">
        Overview
      </h3>
      <span className="ff-inter c-secondary fs-1_125rem lh-1_125rem">
        Show:{' '}
      </span>
      <div
        className="d-flex-row  p-relative hovered"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <span className="ff-inter c-main fs-1_125rem pl-0_375rem">
          {dropdownState}
        </span>
        <div className="d-flex-center pl-0_375rem">
          <TriangleIcon
            className={`${showDropdown ? 'rotate-180deg' : ''} transition-0_5s`}
          />
        </div>
        {showDropdown && (
          <div className="p-absolute top-1_4rem z1 bg-white">
            {DashboardDropdownsConfig.dashboardOptions.map((option, index) => (
              <div
                className="hovered p-0_2rem-0"
                key={index}
                onClick={() => {
                  setDropdownState(option);
                  setShowDropdown(false);
                }}
              >
                <span className="ff-inter c-main fs-1_125rem pl-0_375rem">
                  {option}
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
      {/*isDateRangeSelectorOpen && <div></div>*/}
    </div>
  );
};

export default DashboardHeader;
