import React, { useState, useEffect } from 'react';
import {
  getPhoneNumbers,
  linkPhoneNumberToCampaignId,
} from '../../../core/requests/requests';
import { PhoneNumberProps } from '../../../core/types/_models';
import { useAppData } from '../../../context/AppContext';
import { SelectPhoneNumbersModalProps } from './startCampaignModels';
import { campaignViewValues } from '../../../config/CampaignsConfig';
import { CloseIcon, SearchIcon } from '../../../assets';

const SelectPhoneNumbersModal: React.FC<SelectPhoneNumbersModalProps> = ({
  closeModal,
}) => {
  const { currentCampaign, setCurrentStartCampaignView } = useAppData();
  const [isCampaignCreated, setIsCampaignCreated] = useState<boolean>(false);
  const [chosenPhoneNumber, setChosenPhoneNumber] = useState<string | null>(
    null
  );
  const [searchInput, setSearchInput] = useState<string>('');
  const [filteredPhoneNumbers, setFilteredPhoneNumbers] = useState<
    PhoneNumberProps[]
  >([]);
  const [phoneNumbers, setPhoneNumbers] = useState<PhoneNumberProps[]>([]);
  const currentCampaignId = currentCampaign?.id || '';

  useEffect(() => {
    const fetchPhoneNumbers = async () => {
      try {
        const { data } = await getPhoneNumbers();
        if (data) {
          setPhoneNumbers(data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchPhoneNumbers();
  }, []);

  const handleCheckboxChange = (phoneNumber: string) => {
    if (chosenPhoneNumber === phoneNumber) {
      setChosenPhoneNumber(null); // Uncheck the selected number
    } else {
      setChosenPhoneNumber(phoneNumber); // Check the selected number
    }
  };

  const handleClearInput = () => {
    setSearchInput('');
  };

  const handleConfirm = async () => {
    try {
      if (chosenPhoneNumber && currentCampaignId) {
        const { data } = await linkPhoneNumberToCampaignId(
          chosenPhoneNumber,
          currentCampaignId
        );
        if (data) {
          setIsCampaignCreated(true);
          setTimeout(() => {
            setIsCampaignCreated(false);
            closeModal();
            setCurrentStartCampaignView(campaignViewValues?.sms);
          }, 2000);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (searchInput) {
      setFilteredPhoneNumbers(
        phoneNumbers.filter((phoneNumber) => {
          const searchLower = searchInput.toLowerCase();
          return (
            phoneNumber.phoneNumber.toLowerCase().includes(searchLower) ||
            phoneNumber.locality.toLowerCase().includes(searchLower) ||
            phoneNumber.friendlyName.toLowerCase().includes(searchLower)
          );
        })
      );
    } else {
      setFilteredPhoneNumbers(phoneNumbers);
    }
  }, [searchInput, phoneNumbers]);

  return (
    <div className="d-flex-center w-100 h-100vh p-absolute top-0 left-0">
      {isCampaignCreated ? (
        <div className="d-flex-start-start w-20rem max-h-80vh z4 bg-white p-1_5rem b-radius-0_5rem ">
          <h3 className="text-center pb-1rem pt-1rem">
            Campaign created successfully!
          </h3>
        </div>
      ) : (
        <div className="d-flex-start-start w-30rem max-h-80vh z4 bg-white b-radius-0_5rem ">
          <div className="d-flex-row-space-between w-100 p-1rem bb-1-l-gray">
            <span className="ff-poppins fw-500 fs-0_875rem c-regular">
              Select Phone Numbers:
            </span>
            <div className="d-flex-center icon-hovered" onClick={closeModal}>
              <CloseIcon />
            </div>
          </div>
          <div className="d-flex-start-start w-100">
            <div className="d-flex-center w-100 p-1rem ">
              <div className="d-flex-row w-100 p-relative">
                <input
                  className="settings-modal-input"
                  placeholder="Search"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <div className="d-flex-center p-absolute top-0_8rem left-1rem">
                  <SearchIcon />
                </div>
                {searchInput?.length > 0 ? (
                  <div
                    className="d-flex-center p-absolute top-0_8rem right-1rem icon-hovered"
                    onClick={handleClearInput}
                  >
                    <CloseIcon />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="d-flex-start-start w-100 p-0-1rem overflow-auto">
              {filteredPhoneNumbers?.length > 0 &&
                filteredPhoneNumbers.map((phoneNumber) => (
                  <div
                    className={`${
                      chosenPhoneNumber !== null &&
                      chosenPhoneNumber !== phoneNumber.phoneNumber
                        ? 'c-9ca3af'
                        : ''
                    } d-flex-row  w-100 p-1rem bb-1 hovered`}
                    key={phoneNumber?.phoneNumber}
                    onClick={() =>
                      handleCheckboxChange(phoneNumber.phoneNumber)
                    }
                  >
                    <div className="d-flex-center mr-1rem">
                      <input
                        type="checkbox"
                        className="converstaion-filter-checkbox"
                        checked={chosenPhoneNumber === phoneNumber.phoneNumber}
                      />
                    </div>
                    <span className="ff-inter c-111928 mr-1rem fw-500 fs-0_875rem">
                      {phoneNumber.phoneNumber}
                    </span>
                    <span className="mr-1rem ff-inter c-111928  fs-0_875rem">
                      <span className="fw-600">Area:</span>{' '}
                      {phoneNumber.locality}
                    </span>
                  </div>
                ))}
            </div>
          </div>
          <div className="d-flex-row-space-between w-100 p-1rem">
            <button
              className="btn btn-cancel w-10rem h-2_375rem"
              onClick={closeModal}
            >
              <span className="ff-poppins c-black fs-0_75rem fw-600">
                Close
              </span>
            </button>
            <button
              className="btn btn-primary w-17rem h-2_375rem"
              disabled={chosenPhoneNumber === null}
              onClick={handleConfirm}
            >
              <span className="ff-poppins c-white fs-0_75rem fw-600">
                Confirm
              </span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectPhoneNumbersModal;
