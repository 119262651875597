import React from 'react';
import { InfoIcon, PlusAddIcon } from '../../../assets';
import AgentBioTable from './AgentBioTable';
import AddAgentBioModal from './AddAgentBioModal';
import { useAppData } from '../../../context/AppContext';

const AgentBioComponent: React.FC = () => {
  const { setShowModalBackground } = useAppData();
  const [showAddAgentBioModal, setShowAddAgentBioModal] = React.useState(false);

  const handleOpenAddAgentBioModal = () => {
    setShowAddAgentBioModal(true);
    setShowModalBackground(true);
  };

  const handleCloseAddAgentBioModal = () => {
    setShowAddAgentBioModal(false);
    setShowModalBackground(false);
  };

  return (
    <>
      <div className="d-flex-start-start w-100 shadow-box-9 p-1_5rem b-radius-0_5rem bg-white">
        <div className="d-flex-start-start w-100">
          <div className="d-flex-row mb-1_5rem">
            <span className="c-111928 ff-inter fw-700 fs-1_25rem mr-0_5rem">
              Agent Bio
            </span>
            <div className="d-flex-center icon-hovered">
              <InfoIcon />
            </div>
          </div>
          <button
            className="btn-add-agent-bio mb-1_5rem"
            onClick={handleOpenAddAgentBioModal}
          >
            <div className="d-flex-center mr-0_375rem">
              <PlusAddIcon />
            </div>{' '}
            <span> Add New Agent</span>
          </button>
          <AgentBioTable />
        </div>
      </div>
      {showAddAgentBioModal && (
        <AddAgentBioModal onClose={handleCloseAddAgentBioModal} />
      )}
    </>
  );
};

export default AgentBioComponent;
