import React from 'react';
import { InfoIcon, LogoWithTitlePNG, RocketIcon } from '../../../assets';

const SettingsPaymentsCurrentPlan: React.FC = () => {
  return (
    <div className="d-flex-start-start w-100 bg-white shadow-box-9 b-radius-0_5rem p-1_5rem mb-0_25rem">
      <div className="d-flex-start-start-row pb-1_5rem bb-1 w-100">
        <div className="d-flex-center mr-0_5rem">
          <img alt="" src={LogoWithTitlePNG} className="w-15rem" />
        </div>
        <div className="bg-e0effd p-0_375rem-0_75rem b-radius-0_375rem mt-0_375rem">
          <span className="ff-inter fw-500 fs-0_875rem c-1e429f">
            Essentials
          </span>
        </div>
      </div>
      <div className="d-flex-row-space-between w-100 p-1rem-0 bb-1 mb-1_5rem">
        <span className="ff-inter c-6b7280">Renewal Date: Aug 01 2023</span>
        <span className="ff-inter c-111928 fw-600 fs-1_25rem">$499/month</span>
      </div>
      <div className="d-flex-start-start w-100 mb-1_5rem">
        <div className="d-flex-row mb-1rem">
          <span className="ff-inter c-111928 fw-700 fs-1_25rem">
            Current plan benefits
          </span>
          <div className="d-flex-center ml-0_5rem icon-hovered">
            <InfoIcon />
          </div>
        </div>
        <div className="d-flex-row w-100">
          <div className="d-flex-start-start w-50">
            <div className="d-flex-row p-0_375rem-0">
              <span className="ff-inter fs-0_875rem c-6b7280">
                <span className="fw-500 c-0e9f6e">✓</span>{' '}
                <span className="fw-600 c-111928">35,000</span> orders per month
              </span>
            </div>
            <div className="d-flex-row p-0_375rem-0">
              <span className="ff-inter fs-0_875rem c-6b7280">
                <span className="fw-500 c-0e9f6e">✓</span> Multivariate Testing
              </span>
            </div>
            <div className="d-flex-row p-0_375rem-0">
              <span className="ff-inter fs-0_875rem c-6b7280">
                <span className="fw-500 c-0e9f6e">✓</span> Comparative Reporting
              </span>
            </div>
            <div className="d-flex-row p-0_375rem-0">
              <span className="ff-inter fs-0_875rem c-6b7280">
                <span className="fw-500 c-0e9f6e">✓</span> Unlimited
                integrations
              </span>
            </div>
            <div className="d-flex-row p-0_375rem-0">
              <span className="ff-inter fs-0_875rem c-6b7280">
                <span className="fw-500 c-0e9f6e">✓</span> Team size:{' '}
                <span className="fw-600 c-111928">1-3 developers</span>
              </span>
            </div>
          </div>
          <div className="d-flex-start-start w-50">
            <div className="d-flex-row p-0_375rem-0">
              <span className="ff-inter fs-0_875rem c-6b7280">
                <span className="fw-500 c-0e9f6e">✓</span> Customer Journey
                Builder
              </span>
            </div>
            <div className="d-flex-row p-0_375rem-0">
              <span className="ff-inter fs-0_875rem c-6b7280">
                <span className="fw-500 c-0e9f6e">✓</span> Phone support
              </span>
            </div>
            <div className="d-flex-row p-0_375rem-0">
              <span className="ff-inter fs-0_875rem c-9ca3af td-through">
                <span className="fw-500">✓</span> Custom Templates
              </span>
            </div>
            <div className="d-flex-row p-0_375rem-0">
              <span className="ff-inter fs-0_875rem c-9ca3af td-through">
                <span className="fw-500">✓</span> Exclusive discounts
              </span>
            </div>
            <div className="d-flex-row p-0_375rem-0">
              <span className="ff-inter fs-0_875rem c-9ca3af td-through">
                <span className="fw-500">✓</span> Multivariate Testing
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex-row">
        <button className="btn-subscriptions-secondary mr-1rem w-11rem">
          Cancel subscription
        </button>
        <button className="btn-subscriptions-primary w-11rem">
          <div className="d-flex-center mr-0_5rem">
            <RocketIcon />
          </div>
          <span className="">Upgrade to PRO</span>
        </button>
      </div>
    </div>
  );
};

export default SettingsPaymentsCurrentPlan;
