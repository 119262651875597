import React from 'react';

const SavedLeads: React.FC = () => {
  return (
    <div className="d-flex-center size-max p-1_875rem">
      <h1 className="auth-title mb-1_25rem">Saved Leads</h1>
    </div>
  );
};

export default SavedLeads;
