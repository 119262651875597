import React from 'react';
import { LoginPNG } from '../../assets';
import { Link } from 'react-router-dom';

const NotFound: React.FC = () => {
  return (
    <div className="d-flex-center size-max">
      <img className="login-background z-1" alt="" src={LoginPNG} />
      <h1 className="auth-title mb-1_25rem">Error 404</h1>
      <h4 className="secondary-title">
        Oops, something went wrong. Please try again later.
      </h4>
      <Link className="btn btn-primary mt-2_3rem w-15rem td-none " to="/auth">
        Home
      </Link>
    </div>
  );
};

export default NotFound;
