import React from 'react';
import {
  HubspotRoundIcon,
  PipedriveRoundIcon,
  SalesforceRoundIcon,
  SettingsGearIcon,
  ZohoRoundIcon,
} from '../../../assets';

const IntegrationsComponent: React.FC = () => {
  return (
    <div className="d-flex-start-start w-100 mt-1rem">
      <div className="d-flex-start-start mb-2_375rem">
        <span className="fw-600 c-111928 fs-1_125rem lh-1_125rem ff-poppins mb-0_5rem">
          Integrations
        </span>
      </div>
      <div className="d-flex-row-space-between w-100 mb-2rem">
        <div className="d-flex-start-start bg-white h-8_375rem w-calc-50_minus_1rem b-radius-0_5rem shadow-box-9 p-1rem">
          <div className="d-flex-row-space-between w-100 mb-0_5rem">
            <div className="d-flex-row">
              <div className="d-flex-center mr-0_375rem">
                <SalesforceRoundIcon />
              </div>
              <span className="ff-poppins fw-600 c-111928">Salesforce</span>
            </div>
            <div className="d-flex-center icon-hovered">
              <SettingsGearIcon />
            </div>
          </div>
          <span className="ff-poppins fw-400 c-6b7280 fs-0_875rem lh-0_875rem mb-0_5rem">
            Online platform for sending professionals e-mails to mass
            recipients.
          </span>
          <button className="btn-agent-bio-save w-100">Connect</button>
        </div>
        <div className="d-flex-start-start bg-white h-8_375rem w-calc-50_minus_1rem b-radius-0_5rem shadow-box-9 p-1rem">
          <div className="d-flex-row-space-between w-100 mb-0_5rem">
            <div className="d-flex-row">
              <div className="d-flex-center mr-0_375rem">
                <HubspotRoundIcon />
              </div>
              <span className="ff-poppins fw-600 c-111928">Hubspot</span>
            </div>
            <div className="d-flex-center icon-hovered">
              <SettingsGearIcon />
            </div>
          </div>
          <span className="ff-poppins fw-400 c-6b7280 fs-0_875rem lh-0_875rem mb-0_5rem">
            Online platform for sending professionals e-mails to mass
            recipients.
          </span>
          <button className="btn-agent-bio-save w-100">Connect</button>
        </div>
      </div>
      <div className="d-flex-row-space-between w-100">
        <div className="d-flex-start-start bg-white h-8_375rem w-calc-50_minus_1rem b-radius-0_5rem shadow-box-9 p-1rem">
          <div className="d-flex-row-space-between w-100 mb-0_5rem">
            <div className="d-flex-row">
              <div className="d-flex-center mr-0_375rem">
                <ZohoRoundIcon />
              </div>
              <span className="ff-poppins fw-600 c-111928">Zoho</span>
            </div>
            <div className="d-flex-center icon-hovered">
              <SettingsGearIcon />
            </div>
          </div>
          <span className="ff-poppins fw-400 c-6b7280 fs-0_875rem lh-0_875rem mb-0_5rem">
            Online platform for sending professionals e-mails to mass
            recipients.
          </span>
          <button className="btn-agent-bio-save w-100">Connect</button>
        </div>
        <div className="d-flex-start-start bg-white h-8_375rem w-calc-50_minus_1rem b-radius-0_5rem shadow-box-9 p-1rem">
          <div className="d-flex-row-space-between w-100 mb-0_5rem">
            <div className="d-flex-row">
              <div className="d-flex-center mr-0_375rem">
                <PipedriveRoundIcon />
              </div>
              <span className="ff-poppins fw-600 c-111928">Pipedrive</span>
            </div>
            <div className="d-flex-center icon-hovered">
              <SettingsGearIcon />
            </div>
          </div>
          <span className="ff-poppins fw-400 c-6b7280 fs-0_875rem lh-0_875rem mb-0_5rem">
            Online platform for sending professionals e-mails to mass
            recipients.
          </span>
          <button className="btn-agent-bio-save w-100">Connect</button>
        </div>
      </div>
    </div>
  );
};

export default IntegrationsComponent;
