import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import NotFound from '../pages/errors/NotFound';
import {
  AdditionaBusinessInfoPage,
  AdditionalInfoPage,
  ConfirmationPage,
  ConversationPage,
  DynamicHeader,
  IntroductionPage,
} from '../pages/additionalInfo';

const AdditionalInfoRoutes: React.FC = () => {
  const [progress, setProgress] = useState<number>(0);
  return (
    <div className="d-flex-start-center w-100 bg-light-grey size-max">
      <DynamicHeader progress={progress} />
      <Routes>
        <Route index element={<IntroductionPage setProgress={setProgress} />} />
        <Route
          path="/introduction"
          element={<IntroductionPage setProgress={setProgress} />}
        />
        <Route
          path="/conversation"
          element={<ConversationPage setProgress={setProgress} />}
        />
        <Route
          path="/additional-info"
          element={<AdditionalInfoPage setProgress={setProgress} />}
        />
        <Route
          path="/additional-business-info"
          element={<AdditionaBusinessInfoPage setProgress={setProgress} />}
        />
        <Route
          path="/confirmation"
          element={<ConfirmationPage setProgress={setProgress} />}
        />
        <Route path="/error-404" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default AdditionalInfoRoutes;
