export const maskEmail = (email: string) => {
  // Split the email into local part and domain
  const [localPart, domain] = email.split('@');

  // If the local part is too short, just return the original email
  if (localPart.length < 2) return email;

  // Mask the local part, showing only the first character and replacing the rest with asterisks
  const maskedLocalPart = localPart[0] + '*'.repeat(localPart.length - 1);

  // Combine the masked local part with the domain
  return `${maskedLocalPart}@${domain}`;
};
