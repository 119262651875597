import React from 'react';

import {
  SettingsPaymentsBillingDetails,
  SettingsPaymentsCurrentPlan,
  SettingsPaymentsMethods,
} from './';

const SettingsPayments: React.FC = () => {
  return (
    <div className="d-flex-start-between-row w-100 ">
      <div className="d-flex-start-start w-calc-50_minus_0_75rem">
        <SettingsPaymentsCurrentPlan />
        <SettingsPaymentsBillingDetails />
      </div>
      <SettingsPaymentsMethods />
    </div>
  );
};

export default SettingsPayments;
