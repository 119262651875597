import React, { useState, useEffect, useRef } from 'react';
import { SampleBackgroundPNG } from '../../../assets';
import {
  ApprovedMessagesProps,
  MessageTemplateProps,
} from './startCampaignModels';
import { campaignViewValues } from '../../../config/CampaignsConfig';
import { getMessageTemplatesByType } from '../../../core/requests/requests';
import { useAppData } from '../../../context/AppContext';

const ApprovedMessagesView: React.FC<ApprovedMessagesProps> = ({
  currentMessageType,
}) => {
  const { setCurrentStartCampaignView } = useAppData();
  const [messageTemplatesData, setMessageTemplatesData] = useState<
    MessageTemplateProps[] | []
  >([]);

  const currentMessageTypeRef = useRef(currentMessageType);

  const handleViewChange = (view: string) => {
    setCurrentStartCampaignView(view);
  };

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        if (currentMessageTypeRef.current) {
          const { data } = await getMessageTemplatesByType(
            currentMessageTypeRef.current
          );
          if (data) {
            setMessageTemplatesData(data);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchTemplates();
  }, []);

  return (
    <div className="d-flex-row w-100 f-wrap">
      {messageTemplatesData?.map((template) => (
        <div
          className="d-flex-center p-0_5rem-0_375rem mw-16rem w-auto"
          key={template?.id}
        >
          <div
            className="d-flex-start-start p-1_25rem b-1 b-radius-0_5rem shadow-box-4 border-template-active"
            onClick={() => handleViewChange(campaignViewValues?.edit)}
          >
            <div className="d-flex-center mb-1_5rem">
              <img className="" alt="" src={SampleBackgroundPNG} />
            </div>
            <span className="nunito-bold">{template?.name}</span>
            <div className="d-flex-center bg-soft-wheat mt-1rem b-radius-0_375rem p-0_125rem-0_625rem">
              <span className="text-inter-pending">Pending</span>
            </div>
          </div>
        </div>
      ))}
      {/*<div className="d-flex-center p-0_5rem-0_375rem mw-16rem w-auto">
        <div
          className="d-flex-start-start p-1_25rem b-1 b-radius-0_5rem shadow-box-4 border-template-active"
          onClick={() => handleViewChange(campaignViewValues?.edit)}
        >
          <div className="d-flex-center mb-1_5rem">
            <img className="" alt="" src={SampleBackgroundPNG} />
          </div>
          <span className="nunito-bold">Template name here</span>
           <div className="d-flex-center bg-misty-mint mt-1rem b-radius-0_375rem p-0_125rem-0_625rem">
              <span className="text-inter-approved">Approved</span>
            </div>
        </div>
    </div>*/}
    </div>
  );
};

export default ApprovedMessagesView;
