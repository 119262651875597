import React, { useEffect } from 'react';
import { useAuth } from '../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const HubSpotRedirectPage: React.FC = () => {
  const navigate = useNavigate();
  const { setHubSpotAuthCode } = useAuth();
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);

  useEffect(() => {
    const authorizationCode = url.searchParams.get('code');

    if (authorizationCode) {
      setHubSpotAuthCode(authorizationCode);
      navigate('/leads');
    } else {
      console.error('Authorization code not found');
    }
  });

  return <></>;
};

export default HubSpotRedirectPage;
