import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { PlusIcon } from '../../assets';
import { sendEmailExample } from '../../core/requests/requests';
import { useAuth } from '../../context/AuthContext';

const ConversationPage: React.FC<{ setProgress: (value: number) => void }> = ({
  setProgress,
}) => {
  const { currentUser } = useAuth();
  const [showEmailText, setShowEmailText] = useState(false);
  const [emailText, setEmailText] = useState<string>('');

  const navigate = useNavigate();
  useEffect(() => {
    setProgress(2);
  }, [setProgress]);

  const handleNavigateToAdditionalInfoPage = async () => {
    const userId = currentUser?.id;

    if (userId) {
      await sendEmailExample(userId, emailText);
    }
    navigate('/additional-info');
  };

  const handleAddEmailClick = () => {
    setShowEmailText(true);
  };

  const getProgressBarWidth = () => {
    const length = emailText.length;
    if (length >= 2000) {
      return '100%';
    } else {
      return `${(length / 2000) * 100}%`;
    }
  };

  return (
    <div className="d-flex-center-start b-radius-3rem bg-white w-75 mt-8rem p-3_125rem-4_125rem overflow-h">
      <h3 className="h3-primary mb-1rem ">Let's get acquainted</h3>
      <p className="p-description mb-3rem">
        The magic of Astreaux.AI is that we learn how to write exactly like you
        write over time. To do that, we need a few of your most recent emails.
        Copy and paste the last few outreach emails you've sent.
      </p>
      {showEmailText ? (
        <div className="w-100 h-15rem mb-3rem">
          <textarea
            value={emailText}
            onChange={(e) => setEmailText(e.target.value)}
            placeholder="Paste your email here..."
            rows={10}
            className="w-100 h-100 p-1rem campaign-textarea"
          ></textarea>
        </div>
      ) : (
        <div
          className="d-flex-center add-email-container w-27_5rem h-15rem mb-3rem"
          onClick={handleAddEmailClick}
        >
          <div className="d-flex-row">
            <PlusIcon />
            <span className="poppins-medium fs-1rem pl-1rem">Add an email</span>
          </div>
        </div>
      )}

      <div className="d-flex-row-space-between w-100">
        <div className="d-flex-row-space-between w-100 pr-1_5rem">
          <span className="pr-1_5rem text-description ">
            {Math.min(emailText.length / 20, 100).toFixed(0)}%
          </span>
          <div className="progress-bar w-100 p-relative">
            <div
              className="progress-bar-active"
              style={{ width: getProgressBarWidth() }}
            ></div>
          </div>
        </div>
        <button
          className="btn btn-light-primary w-12rem"
          onClick={handleNavigateToAdditionalInfoPage}
        >
          Next Step
        </button>
      </div>
    </div>
  );
};

export default ConversationPage;
