import React from 'react';
import { ActionArrowIcon, UsersGroupIcon } from '../../../assets';
import { campaignViewValues } from '../../../config/CampaignsConfig';
import { useAppData } from '../../../context/AppContext';

const StartCampaignActionButtons: React.FC = () => {
  const { setCurrentStartCampaignView } = useAppData();
  const handleViewChange = (view: string) => {
    setCurrentStartCampaignView(view);
  };

  return (
    <div className="d-flex-start-start pt-1_5rem w-100">
      <div className="d-flex-row-space-between  f-wrap w-100">
        <div
          className="start-campaign-action-button mw-20rem w-calc-50_minus_0_75rem mr-1_5rem mb-1_5rem"
          onClick={() => handleViewChange(campaignViewValues?.convertOnTheSpot)}
        >
          <div className="d-flex-center-start">
            <div className="d-flex-row pb-0_5rem">
              <UsersGroupIcon />
              <span className="text-campaign-action-button-title pl-0_5rem">
                convert on the spot
              </span>
            </div>
            <span className="text-header-description ">Description</span>
          </div>
          <ActionArrowIcon />
        </div>
        <div
          className="start-campaign-action-button mw-20rem w-calc-50_minus_0_75rem mb-1_5rem"
          onClick={() => handleViewChange(campaignViewValues?.appointment)}
        >
          <div className="d-flex-center-start">
            <div className="d-flex-row pb-0_5rem">
              <UsersGroupIcon />
              <span className="text-campaign-action-button-title pl-0_5rem">
                nurture to appointment
              </span>
            </div>
            <span className="text-header-description ">Description</span>
          </div>
          <ActionArrowIcon />
        </div>
      </div>
      <div className="d-flex-row-space-between f-wrap w-100">
        <div
          className="start-campaign-action-button mw-20rem w-calc-50_minus_0_75rem mr-1_5rem mb-1_5rem"
          onClick={() => handleViewChange(campaignViewValues?.reminder)}
        >
          <div className="d-flex-center-start">
            <div className="d-flex-row pb-0_5rem">
              <UsersGroupIcon />
              <span className="text-campaign-action-button-title pl-0_5rem">
                reminder
              </span>
            </div>

            <span className="text-header-description ">Description</span>
          </div>
          <ActionArrowIcon />
        </div>
        <div
          className="start-campaign-action-button mw-20rem w-calc-50_minus_0_75rem mb-1_5rem"
          onClick={() => handleViewChange(campaignViewValues?.custom)}
        >
          <div className="d-flex-center-start">
            <div className="d-flex-row pb-0_5rem">
              <UsersGroupIcon />
              <span className="text-campaign-action-button-title pl-0_5rem">
                custom
              </span>
            </div>
            <span className="text-header-description ">Description</span>
          </div>
          <ActionArrowIcon />
        </div>
      </div>
    </div>
  );
};

export default StartCampaignActionButtons;
