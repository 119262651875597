import React from 'react';
import { useAppData } from '../../../context/AppContext';
import { AddIcon } from '../../../assets';

const AgentBioTable: React.FC = () => {
  const { leadsList } = useAppData();
  return (
    <div className="d-flex-start-start w-100">
      {leadsList && leadsList?.length > 0 ? (
        <div className="d-flex-start-start overflow-auto w-100">
          <table className="settings-agent-bio-table mt-1_5rem">
            <thead>
              <tr>
                <th>
                  <div className="d-flex-center"></div>
                </th>
                <th>Name</th>
                <th>Email</th>
                <th>Title</th>
                <th>Country</th>
                <th>City</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {leadsList.map((lead) => (
                <tr key={lead.id}>
                  <td>
                    <div className="d-flex-center"></div>
                  </td>
                  <td className="agent-bio-table-name">{`${lead.firstname} ${lead.lastname}`}</td>
                  <td>{lead.email}</td>
                  <td>{lead?.company || 'N/A'}</td>
                  <td>{lead?.state || 'N/A'}</td>
                  <td>{lead?.city}</td>
                  <td>
                    <div className="d-flex-center icon-hovered">
                      <AddIcon />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="d-flex-center w-100 p-1_5rem">
          <span className="fs-1_5rem text-header-description fw-600">
            No agents are available.
          </span>
        </div>
      )}
    </div>
  );
};

export default AgentBioTable;
