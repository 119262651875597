import React from 'react';
import {
  ConversationLinearChart,
  DashboardCharts,
  DashboardHeader,
  DashboardTable,
  VisibilityDrop,
} from './components';

const Dashboard: React.FC = () => {
  return (
    <div className="d-flex-start-start w-100 p-1_875rem">
      <VisibilityDrop />
      <DashboardHeader />
      <DashboardCharts />
      <ConversationLinearChart />
      <DashboardTable />
    </div>
  );
};

export default Dashboard;
