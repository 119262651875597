import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { AuthProps } from '../core/types/_models';
import { API_URL } from '../helpers/envProcessor';

const onRequest = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  const storedAuthLogin = localStorage.getItem('authLogin');
  const parsedAuthLogin: AuthProps = storedAuthLogin
    ? JSON.parse(storedAuthLogin)
    : null;

  if (config.headers) {
    config.headers['Authorization'] = `Bearer ${parsedAuthLogin?.accessToken}`;
  }

  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};
const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  if (error.response) {
    // Access Token was expired
    if (error.response.status === 401) {
      const storedAuthLogin = localStorage.getItem('authLogin');
      const parsedAuthLogin: AuthProps = storedAuthLogin
        ? JSON.parse(storedAuthLogin)
        : null;

      const userId = parsedAuthLogin?.userId;
      const refreshToken = parsedAuthLogin?.refreshToken;
      try {
        const rs = await axios.post(`${API_URL}/auth-token-refresh`, {
          userId,
          refreshToken,
        });

        localStorage.setItem('authLogin', JSON.stringify(rs.data));

        // Update the access token in the original request and retry it
        if (error?.config && error.config.headers) {
          error.config.headers['Authorization'] =
            `Bearer ${rs.data.accessToken}`;
        }

        return axios(error.config ? error.config : {});
      } catch (_error) {
        return Promise.reject(_error);
      }
    }
  }
  return Promise.reject(error);
};

export const setupInterceptorsTo = (
  axiosInstance: AxiosInstance
): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};
