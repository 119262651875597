import React from 'react';
import { campaignViewValues } from '../../../config/CampaignsConfig';
import { StartCampaignHeaderProps } from './startCampaignModels';
import {
  CampaignBackArrowIcon,
  WhatsappBigIcon,
  FacebookBigIcon,
  InstagramBigIcon,
  SMSBigIcon,
  HeaderPlusIcon,
} from '../../../assets';
import { useAppData } from '../../../context/AppContext';

interface IconMappingType {
  [key: string]: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}

const IconMapping: IconMappingType = {
  WhatsappIcon: WhatsappBigIcon,
  FacebookIcon: FacebookBigIcon,
  InstagramIcon: InstagramBigIcon,
  SMSIcon: SMSBigIcon,
};

const StartCampaignHeader: React.FC<StartCampaignHeaderProps> = ({
  currentCampaignConfig,
  campaignDataId,
}) => {
  const { setCurrentStartCampaignView } = useAppData();
  const handleViewChange = (view: string) => {
    setCurrentStartCampaignView(view);
  };

  const handleBack = (view: string) => {
    setCurrentStartCampaignView(view);
  };

  const IconComponent = IconMapping[currentCampaignConfig?.iconName || ''];

  return (
    <>
      <div className="d-flex-row-space-between w-100 pb-1rem">
        <div className="d-flex-center-start">
          <div className="d-flex-row">
            {currentCampaignConfig?.backButton && (
              <div
                className="d-flex-center mr-0_5rem"
                onClick={() =>
                  handleBack(
                    currentCampaignConfig?.backButton
                      ? currentCampaignConfig?.backButton
                      : ''
                  )
                }
              >
                <CampaignBackArrowIcon className="icon-hovered" />
              </div>
            )}
            {currentCampaignConfig?.iconName && IconComponent && (
              <IconComponent className="w-1_75rem h-1_75rem mr-0_5rem" />
            )}
            <h2 className="text-header-title-h2">
              {currentCampaignConfig?.campaignTitle}
            </h2>
          </div>
          <span className="text-header-description pt-0_5rem">
            {currentCampaignConfig?.description}
          </span>
        </div>
        <div className="d-flex-row">
          {currentCampaignConfig?.settingsButton && (
            <button
              className={`${!currentCampaignConfig?.showLeads ? 'btn-component-header-primary' : 'btn-component-header-secondary'} mr-1rem`}
              onClick={() => handleViewChange(campaignViewValues?.settings)}
            >
              Settings
            </button>
          )}
          {currentCampaignConfig?.campaignsButton && (
            <button
              className={`${currentCampaignConfig?.showLeads ? 'btn-component-header-primary' : 'btn-component-header-secondary'}`}
              onClick={() =>
                handleViewChange(campaignViewValues?.startCampaign)
              }
              disabled={!campaignDataId}
            >
              Campaign
            </button>
          )}
          {currentCampaignConfig?.addNewButton && (
            <button
              className="btn-add-primary"
              onClick={() => handleViewChange(campaignViewValues?.add_new)}
            >
              <HeaderPlusIcon />
              <span className="roboto-medium-soft-silver pl-0_625rem">
                Add new
              </span>
            </button>
          )}
        </div>
      </div>
      {currentCampaignConfig?.underLine && <div className="w-100 bb-1"></div>}
    </>
  );
};

export default StartCampaignHeader;
