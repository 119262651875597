export function formatFullDateTime(datetimeString: string): string {
  const date = new Date(datetimeString);

  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZoneName: 'short',
  };

  const formattedDate = date.toLocaleString('en-US', options);

  // Remove the time zone name and replace it with "P1"
  const formattedDateWithoutTZ = formattedDate.replace(/ GMT.*/, '').trim();

  return `${formattedDateWithoutTZ} P1`;
}

export function formatDate(datetimeString: string): string {
  const date = new Date(datetimeString);

  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  };

  const formattedDate = date.toLocaleString('en-US', options);

  // Remove the time zone name and replace it with "P1"
  const formattedDateWithoutTZ = formattedDate.replace(/ GMT.*/, '').trim();

  return formattedDateWithoutTZ;
}
