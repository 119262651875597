import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { ForgotPasswordPNG, ResetPasswordPNG } from '../../assets';
import { forgotPassword } from '../../core/requests/requests';
import { emailValidationSchema } from './components/ValidationSchemaItems';
import { useAuth } from '../../context/AuthContext';

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const { emailToReset, setEmailToReset } = useAuth();
  const [focusedFields, setFocusedFields] = useState({
    email: false,
  });

  const initialValues = {
    email: '',
  };

  const validationSchema = Yup.object().shape({
    email: emailValidationSchema,
  });

  const handleFieldFocus = (fieldName: string) => {
    setFocusedFields((prevFocusedFields) => ({
      ...prevFocusedFields,
      [fieldName]: true,
    }));
  };

  const handleFieldBlur = (fieldName: string) => {
    setFocusedFields((prevFocusedFields) => ({
      ...prevFocusedFields,
      [fieldName]: false,
    }));
  };

  const handleSubmit = async (values: { email: string }) => {
    try {
      const { data } = await forgotPassword(values?.email);
      if (data) {
        setEmailToReset(values?.email);
      }
    } catch {
      console.log('Forgot password failed');
    }
  };

  const handleNavigateToLogin = () => {
    setEmailToReset(null);
    navigate('/login');
  };

  return (
    <div className="d-flex-center size-max ">
      <div className="forgot-password-block d-flex-center-start w-65 max-w-70rem bg-white p-relative z3">
        {!emailToReset ? (
          <div className="d-flex-start-start w-100 ">
            <div className="d-flex-start-start w-60">
              <h2 className="h2-primary mb-0_75rem">Reset your password</h2>
              <p className="p-description mb-3rem w-85">
                Have no fear. We’ll email you instructions to reset your
                password. If you don't have access to your email we can try
                account recovery
              </p>

              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, isValid }) => (
                  <Form className="w-100">
                    <div className="d-flex-row form-row mb-1_5rem">
                      <div className="form-item  w-100">
                        <Field
                          type="text"
                          name="email"
                          className={`form-control ${errors.email ? 'error-border' : ''} `}
                          placeholder="Mail Address"
                          onFocus={() => handleFieldFocus('email')}
                          onBlur={() => handleFieldBlur('email')}
                        />
                        {errors.email && (
                          <div className="form-error">{errors.email}</div>
                        )}
                        {focusedFields.email && (
                          <div className="d-flex-center title-container">
                            <span
                              className={`form-title ${errors.email ? 'error-title' : 'correct-title'}`}
                            >
                              Your Email
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary w-100"
                      disabled={!isValid}
                    >
                      Reset Password
                    </button>
                  </Form>
                )}
              </Formik>
              <img
                alt=""
                src={ForgotPasswordPNG}
                className="w-30 p-absolute mw-15rem right-_-3rem bottom-_-3rem "
              />
            </div>
          </div>
        ) : (
          <div className="d-flex-start-start w-100 ">
            <div className="d-flex-start-start w-60">
              <h2 className="h2-primary mb-0_75rem">Check email</h2>
              <p className="p-description  w-85">
                We have sent the reset email to
              </p>
              <span>{emailToReset}</span>

              <button
                type="submit"
                className="btn btn-primary w-100 mt-6rem mb-3_25rem"
                onClick={handleNavigateToLogin}
              >
                Return to Login
              </button>
            </div>
            <img
              alt=""
              src={ResetPasswordPNG}
              className="w-30 p-absolute mw-15rem right-_-3rem bottom-_-3rem "
            />
          </div>
        )}
        <div className="d-flex-start-start p-absolute left-5rem bottom-_-3rem bg-white h-4rem bbl-radius-1_25rem bbr-radius-1_25rem w-50">
          <div className="w-100 h-1rem shadow-box-bottom z1"></div>
          <div className="d-flex-center w-100 h-3rem z1">
            <Link to="/" className="primary-link">
              Already have an Square account? Log in
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
