import React from 'react';
import { ArrowRightWhiteIcon, FileIcon } from '../../../assets';
import { campaignViewValues } from '../../../config/CampaignsConfig';
import { useAppData } from '../../../context/AppContext';

const ChoosedMediaView: React.FC = () => {
  const { setCurrentStartCampaignView } = useAppData();
  const handleViewChange = (view: string) => {
    setCurrentStartCampaignView(view);
  };

  return (
    <div className="d-flex-start-start w-100 mb-1_5rem">
      <div className="d-flex-start-start w-45 mw-20rem p-1_25rem bg-pastel-mint b-radius-0_5rem">
        <div className="d-flex-row">
          <FileIcon />
          <h3 className="text-inter-bold-h3 pl-1rem">
            Ready to send templates
          </h3>
        </div>
        <span className="text-template-description pt-1rem pb-1_5rem">
          Lorem ipsum dolor sit amet consectetur.
        </span>
        <button
          className="btn-template-primary mb-0_5rem"
          onClick={() =>
            handleViewChange(campaignViewValues?.approved_messages)
          }
        >
          <span className="nunito-semibold-white pr-0_5rem">Start</span>
          <ArrowRightWhiteIcon />
        </button>
      </div>
    </div>
  );
};

export default ChoosedMediaView;
