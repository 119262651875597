import React, { useEffect, useState } from 'react';
import {
  ConversationHighlights,
  HighlightsChat,
  HighlightsHeader,
} from './components';
import { ChatByIdProps } from '../../core/types/_models';
import { useParams } from 'react-router-dom';
import { getChatById } from '../../core/requests/requests';

const Highlights: React.FC = () => {
  const [chatById, setChatById] = useState<ChatByIdProps | null>(null);
  const { chatId } = useParams<{ chatId: string }>();

  const fetchChatById = async () => {
    if (chatId) {
      try {
        const { data } = await getChatById(chatId);
        if (data) {
          setChatById(data);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchChatById();
  }, [chatId]);

  return (
    <div className="d-flex-start-start w-100">
      <HighlightsHeader chatById={chatById} />
      <div className="d-flex-start-start-row w-100">
        <ConversationHighlights chatById={chatById} />
        <HighlightsChat chatById={chatById} fetchChatById={fetchChatById} />
      </div>
    </div>
  );
};

export default Highlights;
