import React, { useState } from 'react';
import { AddImageIcon, ChatAvatarIcon } from '../../../assets';
import { AddNewViewProps } from './startCampaignModels';
import { createMessageTemplate } from '../../../core/requests/requests';
import { MessageTemplatePayloadProps } from '../../../core/types/_models';
import { campaignViewValues } from '../../../config/CampaignsConfig';
import { useAppData } from '../../../context/AppContext';

const AddNewView: React.FC<AddNewViewProps> = ({ currentMessageType }) => {
  const { setCurrentStartCampaignView } = useAppData();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const handleAddTemplate = async () => {
    try {
      if (currentMessageType && title && description) {
        const messagePayload: MessageTemplatePayloadProps = {
          name: title,
          text: description,
        };
        const newTemplate = await createMessageTemplate(
          currentMessageType,
          messagePayload
        );
        if (newTemplate?.data) {
          setCurrentStartCampaignView(campaignViewValues?.approved_messages);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="d-flex-start-start pb-1_5rem w-100 ">
      <div className="d-flex-start-start-row p-1rem-0 w-100 bb-1">
        <div className="d-flex-start-start w-55 mr-1_75rem p-1rem-0">
          <button className="btn-add-image">
            <AddImageIcon />
            <span className="pl-0_5rem">Add image</span>
          </button>
          <div className="d-flex-start-start w-100 pt-1_5rem">
            <span className="text-inter-regular pb-0_5rem">Title</span>
            <div className="d-flex-row w-100">
              <input
                placeholder="Title"
                className="campaign-convert-input "
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
          </div>
          <div className="d-flex-start-start w-100 pt-1_5rem">
            <span className="text-inter-regular pb-0_5rem">Descriptions</span>
            <div className="d-flex-row w-100">
              <textarea
                placeholder="Descriptions"
                className="campaign-template-textarea w-100"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>
          <button
            className="mt-0_5rem btn-roboto-primary"
            onClick={handleAddTemplate}
          >
            Submit
          </button>
        </div>
        <div className="d-flex-start-start w-45 bg-header b-radius-0_625rem pt-1_75rem pl-1_75rem p-relative">
          <div className="d-flex-start-start bg-white btl-radius-1_125rem bl-1 bt-1 w-100 pt-2_5rem pl-2_5rem pb-1rem">
            <div className="d-flex-start-start-row  w-100">
              <div className="d-flex-center bg-primary-message p-0_75rem mr-0_75rem btl-radius-0_875rem btr-radius-0_875rem bbl-radius-0_875rem ">
                <span className="text-message-inter-regular">
                  Et ipsum tincidunt justo nunc. Porttitor non enim id
                  scelerisque cursus et vel et. Etiam dictum congue adipiscing
                  commodo nunc. Aliquet nunc dignissim in pellentesque nisi
                  donec eu nunc.
                </span>
              </div>
              <div className="d-flex-center mr-0_5rem">
                <ChatAvatarIcon />
              </div>
            </div>
          </div>
          <div className="gradient-overlay"></div>
        </div>
      </div>
    </div>
  );
};

export default AddNewView;
