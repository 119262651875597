import React from 'react';
import DashboardSearchbar from './DashboardSearchbar';
import { PlusIcon } from '../../../assets';
import { DashboardTemporaryData } from './dashboardTemporaryData';

const DashboardTable: React.FC = () => {
  return (
    <div className="d-flex-start-start w-100 bg-white b-radius-1_25rem mt-1_75rem p-1_125rem-1_625rem">
      <DashboardSearchbar />
      <div className="d-flex-start-start overflow-auto w-100">
        <table className="dashboard-conversation-table mt-1_5rem">
          <thead>
            <tr>
              <th>
                <div className="d-flex-center">
                  <input type="checkbox" className="checkbox-form" />
                </div>
              </th>
              <th>Name</th>
              <th>Email</th>
              <th>phone number</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {DashboardTemporaryData &&
              DashboardTemporaryData.map((conversation) => (
                <tr key={conversation.id}>
                  <td>
                    <div className="d-flex-center">
                      <input
                        type="checkbox"
                        name="useLeads"
                        className="checkbox-input"
                      />
                    </div>
                  </td>
                  <td>
                    <div className="d-flex-row">
                      <div className="d-flex-start-start">
                        <span className="h5-primary-poppins mb-0_25rem ">
                          {conversation?.name}
                        </span>
                        <div className="d-flex-row">
                          <PlusIcon className="w-0_75rem" />
                          <span className="c-0062ff fs-0_875rem ml-0_5rem">
                            Add Tag
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>{conversation?.email}</td>
                  <td>{conversation?.phone}</td>
                  <td>
                    <div className="d-flex-center-end-row">
                      <div className="d-flex-row b-1 b-radius-0_625rem overflow-h ">
                        <div className="d-flex-row">
                          <div className="d-flex-row bg-f6effb p-0_5rem-1rem">
                            <div className="h-0_5rem w-0_5rem b-radius-0_25rem bg-a461d8"></div>
                            <span className="pl-0_625rem h5-primary-poppins">
                              {conversation?.leadType}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex-row p-0_5rem-1rem">
                          <div className="h-0_5rem w-0_5rem b-radius-0_25rem bg-ffc542"></div>
                          <span className="pl-0_625rem h5-primary-poppins">
                            Consultation {conversation?.consultationStatus}
                          </span>
                        </div>
                        <div className="d-flex-row p-0_5rem-1rem">
                          <div className="h-0_5rem w-0_5rem b-radius-0_25rem bg-82c43c"></div>
                          <span className="pl-0_625rem h5-primary-poppins">
                            Deal {conversation?.dealStatus}
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DashboardTable;
