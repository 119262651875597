import React, { useEffect } from 'react';
import {
  HUBSPOT_CLIENT_ID,
  HUBSPOT_REDIRECT_URI,
} from '../../../helpers/envProcessor';

const AuthorizationComponent = () => {
  useEffect(() => {
    // Construct the authorization URL
    if (HUBSPOT_CLIENT_ID && HUBSPOT_REDIRECT_URI) {
      const authUrl =
        'https://app-eu1.hubspot.com/oauth/authorize' +
        `?client_id=${encodeURIComponent(HUBSPOT_CLIENT_ID)}` +
        `&scope=crm.lists.read%20crm.objects.contacts.read%20crm.schemas.contacts.read` +
        `&redirect_uri=${encodeURIComponent(HUBSPOT_REDIRECT_URI)}`;

      console.log(authUrl); // Log the auth URL

      // Redirect the user
      window.location.href = authUrl; // Redirect the user using window.location.href
    }
  }, []); // Run this effect only once when the component mounts

  return (
    <div>
      {/* Optionally, you can render a loading message or spinner here */}
    </div>
  );
};

export default AuthorizationComponent;
