import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import {
  ConversationCard,
  ConversationChartsFunctions,
  ConversationFilters,
  ConversationsHeader,
  ConversationsList,
} from '../conversations/components';
import {
  AllChatProps,
  ConversationFiltersProps,
} from '../../core/types/_models';
import { getChatsByCampaignId } from '../../core/requests/requests';

const CampaignById: React.FC = () => {
  const { campaignId } = useParams();
  const navigate = useNavigate();
  const [chats, setChats] = useState<AllChatProps[]>([]);
  const [filters, setFilters] = useState<ConversationFiltersProps>({
    campaignName: '',
    words: '',
    conversation: '',
    userName: '',
    platforms: [],
  });

  const handleNavigateToHighlights = (chatId: string) => {
    navigate(`/conversations/${chatId}`);
  };

  const handleGetAllChats = async () => {
    try {
      if (campaignId) {
        const { data } = await getChatsByCampaignId(filters, campaignId);
        if (data) {
          setChats(data?.chats);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    handleGetAllChats();
  }, [campaignId]);

  return (
    <div className="d-flex-start-start w-100 p-1_875rem">
      <ConversationsHeader />
      <div className="d-flex-start-start-row w-100">
        <ConversationFilters
          filters={filters}
          setFilters={setFilters}
          setChats={setChats}
        />
        <div className="d-flex-start-start w-calc-100_minus_20rem pl-2_5rem">
          <ConversationChartsFunctions />
          <div className="d-flex-start-start w-100">
            <ConversationsList />
            {chats?.length > 0 &&
              chats?.map((chat) => (
                <div
                  className="d-flex-start-start w-100 hovered"
                  onClick={() => handleNavigateToHighlights(chat?.id)}
                  key={chat?.id}
                >
                  <ConversationCard chat={chat} />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignById;
