import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';

import SocialMediaConnections from './components/SocialMediaConnections';
import { BusinessProps } from '../../core/types/_models';
import { useAuth } from '../../context/AuthContext';
import { ExportIcon } from '../../assets';

const AdditionaBusinessInfoPage: React.FC<{
  setProgress: (value: number) => void;
}> = ({ setProgress }) => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [focusedFields, setFocusedFields] = useState({
    businessName: false,
    city: false,
    website: false,
    companyLinkedin: false,
    aboutBusiness: false,
    moreInfo: false,
  });
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setProgress(3);

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [setProgress]);

  const initialValues = {
    id: currentUser?.id ? currentUser?.id : '',
    businessName: '',
    city: '',
    website: '',
    companyLinkedin: '',
    aboutBusiness: '',
    moreInfo: '',
  };

  const handleFieldFocus = (fieldName: string) => {
    setFocusedFields((prevFocusedFields) => ({
      ...prevFocusedFields,
      [fieldName]: true,
    }));
  };

  const handleFieldBlur = (fieldName: string) => {
    setFocusedFields((prevFocusedFields) => ({
      ...prevFocusedFields,
      [fieldName]: false,
    }));
  };

  const handleNavigateToConfirmationPage = () => {
    navigate('/confirmation');
  };

  const handleSubmit = (values: BusinessProps) => {
    console.log(values);
    handleNavigateToConfirmationPage();
  };

  const handleDivClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      // Handle the selected files
      console.log(files);
      // You can perform any file upload operations here
    }
  };

  return (
    <div className="d-flex-start-start b-radius-3rem bg-white w-75 mt-8rem p-3_125rem-4_125rem bg-white mb-3rem">
      <h3 className="h3-primary mb-1rem ">More about the business</h3>
      <p className="p-description mb-2rem">
        Feel free to edit anything that isn't quite right.
      </p>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isValid }) => (
          <Form className="w-100">
            <div className="d-flex-row-space-between form-row mb-2_375rem w-100">
              <div className="form-item  w-calc-50_minus_0_875rem">
                <Field
                  type="text"
                  name="businessName"
                  className="form-control"
                  placeholder="JP Real Estate"
                  onFocus={() => handleFieldFocus('businessName')}
                  onBlur={() => handleFieldBlur('businessName')}
                />

                {focusedFields.businessName && (
                  <div className="d-flex-center title-container">
                    <span className="form-title correct-title">Business</span>
                  </div>
                )}
              </div>
              <div className="form-item  w-calc-50_minus_0_875rem">
                <Field
                  type="text"
                  name="city"
                  className="form-control"
                  placeholder="City, State"
                  onFocus={() => handleFieldFocus('city')}
                  onBlur={() => handleFieldBlur('city')}
                />

                {focusedFields.city && (
                  <div className="d-flex-center title-container">
                    <span className="form-title correct-title">City</span>
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex-row-space-between form-row mb-2_375rem w-100">
              <div className="form-item  w-calc-50_minus_0_875rem">
                <Field
                  type="text"
                  name="website"
                  className="form-control"
                  placeholder="Website"
                  onFocus={() => handleFieldFocus('website')}
                  onBlur={() => handleFieldBlur('website')}
                />
                {focusedFields.website && (
                  <div className="d-flex-center title-container">
                    <span className="form-title  correct-title">Website</span>
                  </div>
                )}
              </div>
              <div className="form-item  w-calc-50_minus_0_875rem">
                <Field
                  type="text"
                  name="companyLinkedin"
                  className="form-control"
                  placeholder=" Optional Company LinkedIn URL"
                  onFocus={() => handleFieldFocus('companyLinkedin')}
                  onBlur={() => handleFieldBlur('companyLinkedin')}
                />
                {focusedFields.companyLinkedin && (
                  <div className="d-flex-center title-container">
                    <span className="form-title correct-title">
                      Company Linkedin
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="w-100 mb-1rem">
              <span className="fs-0_875rem">Tell us about your business</span>
              <div className="form-item  w-100 mt-0_75rem">
                <Field
                  type="text"
                  name="aboutBusiness"
                  component="textarea"
                  className="textarea-control"
                  placeholder="We are a small real estate firm  with 8 employees."
                  onFocus={() => handleFieldFocus('aboutBusiness')}
                  onBlur={() => handleFieldBlur('aboutBusiness')}
                />
                {/*focusedFields.about && (
                  <div className="d-flex-center title-container">
                    <span className="form-title correct-title">
                      Hello my name...
                    </span>
                  </div>
                )*/}
              </div>
            </div>
            {/*<div className="w-100 mb-2_375rem">
              <span className="fs-0_875rem">Give us more information</span>
              <div className="form-item  w-100 mt-0_75rem">
                <Field
                  type="text"
                  name="moreInfo"
                  component="textarea"
                  className="textarea-control"
                  placeholder="Our firm first opened in 1989 and our founders are Bill and Mary Hugets"
                  onFocus={() => handleFieldFocus('moreInfo')}
                  onBlur={() => handleFieldBlur('moreInfo')}
                />
                focusedFields.about && (
                  <div className="d-flex-center title-container">
                    <span className="form-title correct-title">
                      Hello my name...
                    </span>
                  </div>
                )
              </div>
            </div>*/}
            <div className="d-flex-start-start w-100">
              <div
                className="d-flex-row w-100 mb-2_375rem hovered"
                onClick={handleDivClick}
              >
                <div className="d-flex-center mr-0_5rem">
                  <ExportIcon />
                </div>
                <span className="ff-inter fw-500 c-1751d0">Import Files</span>
              </div>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
            </div>
            <SocialMediaConnections />
            <button
              type="submit"
              className="btn btn-light-primary w-100"
              disabled={!isValid}
            >
              Final Step
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AdditionaBusinessInfoPage;
