import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import {
  changeEmailExample,
  getEmailExample,
  sendEmailExample,
} from '../../core/requests/requests';
import { PlusIcon } from '../../assets';
import { EmailExamplesProps } from '../../core/types/_models';

const EmailAssistant: React.FC = () => {
  const { currentUser } = useAuth();
  const [showEmailText, setShowEmailText] = useState(false);
  const [emailText, setEmailText] = useState<string>('');
  const [emailExamples, setEmailExamples] = useState<EmailExamplesProps[] | []>(
    []
  );
  const [modifiedEmailTexts, setModifiedEmailTexts] = useState<{
    [key: string]: string;
  }>({});
  const [savingStates, setSavingStates] = useState<{ [key: string]: string }>(
    {}
  );
  const userId = currentUser?.id;

  const fetchEmailExamples = async () => {
    try {
      if (userId) {
        const { data } = await getEmailExample(userId);
        if (data) {
          setEmailExamples(
            data.sort(
              (a: EmailExamplesProps, b: EmailExamplesProps) =>
                new Date(b.updatedAt).getTime() -
                new Date(a.updatedAt).getTime()
            )
          );
          const initialTexts = data.reduce(
            (acc: any, email: EmailExamplesProps) => {
              acc[email.id] = email.emailText;
              return acc;
            },
            {}
          );
          setModifiedEmailTexts(initialTexts);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSendEmailExample = async () => {
    if (userId) {
      const { data } = await sendEmailExample(userId, emailText);
      if (data) {
        fetchEmailExamples();
      }
    }
    setShowEmailText(false);
  };

  const handleChangeEmailExample = async (
    emailId: string,
    changedEmailText: string
  ) => {
    if (emailId) {
      try {
        setSavingStates((prev) => ({ ...prev, [emailId]: 'Saving...' }));
        const { data } = await changeEmailExample(emailId, changedEmailText);
        if (data) {
          fetchEmailExamples();
          setSavingStates((prev) => ({ ...prev, [emailId]: 'Saved' }));
          setTimeout(() => {
            setSavingStates((prev) => ({ ...prev, [emailId]: 'Save' }));
          }, 2000);
        }
      } catch (error) {
        console.log(error);
        setSavingStates((prev) => ({ ...prev, [emailId]: 'Save' }));
      }
    }
  };

  const handleAddEmailClick = () => {
    setShowEmailText(true);
  };

  const handleEmailTextChange = (id: string, newText: string) => {
    setModifiedEmailTexts((prev) => ({
      ...prev,
      [id]: newText,
    }));
  };

  useEffect(() => {
    fetchEmailExamples();
  }, []);

  return (
    <div className="d-flex-start-start w-100 p-1_875rem">
      <h3 className="h2-primary mb-1_25rem">Email examples:</h3>
      {emailExamples?.length > 0 &&
        emailExamples.map((email) => (
          <div className="w-100 mb-1rem p-relative" key={email?.id}>
            <textarea
              value={modifiedEmailTexts[email.id] || ''}
              onChange={(e) => handleEmailTextChange(email.id, e.target.value)}
              placeholder="Paste your email here..."
              rows={10}
              className="w-100 p-1rem settings-textarea"
            ></textarea>
            <button
              className="btn btn-light-primary w-8rem p-absolute bottom-1rem right-1rem"
              onClick={() =>
                handleChangeEmailExample(email.id, modifiedEmailTexts[email.id])
              }
            >
              {savingStates[email.id] || 'Save'}
            </button>
          </div>
        ))}

      {showEmailText ? (
        <div className="w-100  mb-3rem">
          <textarea
            value={emailText}
            onChange={(e) => setEmailText(e.target.value)}
            placeholder="Paste your email here..."
            rows={10}
            className="w-100  p-1rem settings-textarea pb-4rem"
          ></textarea>
          <button
            className="btn btn-light-primary w-100 mt-0_5rem"
            onClick={handleSendEmailExample}
          >
            Send
          </button>
        </div>
      ) : (
        <div
          className="d-flex-center add-email-container w-100 mb-3rem p-1rem"
          onClick={handleAddEmailClick}
        >
          <div className="d-flex-row">
            <PlusIcon />
            <span className="poppins-medium fs-1rem pl-1rem">Add an email</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailAssistant;
