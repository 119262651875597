import React from 'react';
import { AdCampaignSelectionModalProps } from './startCampaignModels';
import { CloseIcon, SearchIcon } from '../../../assets';

export interface AdCampaignSelectionData {
  campaignName: string;
  cmapaignId: string;
  campaignStatus: string;
  campaignPhone: string;
  createdAt: string;
}

const AdCampaignSelectionModal: React.FC<AdCampaignSelectionModalProps> = ({
  onClose,
  onConfirm,
}) => {
  const adCampaignData: AdCampaignSelectionData[] = [
    {
      campaignName: 'Campaign 1',
      cmapaignId: '123',
      campaignStatus: 'Active',
      campaignPhone: '1234567890',
      createdAt: '2022-01-01T00:00:00.000Z',
    },
    {
      campaignName: 'Campaign 2',
      cmapaignId: '456',
      campaignStatus: 'Inactive',
      campaignPhone: '9876543210',
      createdAt: '2022-01-01T00:00:00.000Z',
    },
    {
      campaignName: 'Campaign 3',
      cmapaignId: '789',
      campaignStatus: 'Active',
      campaignPhone: '1234567890',
      createdAt: '2022-01-01T00:00:00.000Z',
    },
    {
      campaignName: 'Campaign 4',
      cmapaignId: '012',
      campaignStatus: 'Inactive',
      campaignPhone: '9876543210',
      createdAt: '2022-01-01T00:00:00.000Z',
    },
  ];

  return (
    <div className="d-flex-center w-100 h-100vh p-absolute top-0 left-0">
      <div className="d-flex-start-between w-50rem h-80vh z4 bg-white  b-radius-0_5rem">
        <div className="d-flex-row-space-between w-100 p-1rem-1_5rem bb-1-l-gray">
          <span className="ff-poppins fw-500 fs-0_875rem c-regular">
            Select Ad Campaigns:
          </span>
          <div className="d-flex-center icon-hovered" onClick={onClose}>
            <CloseIcon />
          </div>
        </div>
        <div className="d-flex-start-start w-100 h-calc-100-8rem">
          <div className="d-flex-center w-100 p-1rem-1_5rem ">
            <div className="d-flex-row w-100 p-relative">
              <input
                className="settings-modal-input"
                placeholder="Search for ad campaigns"
              />
              <div className="d-flex-center p-absolute top-0_8rem left-1rem">
                <SearchIcon />
              </div>
            </div>
          </div>
          <div className="d-flex-start-start w-100 pl-1_125rem overflow-auto">
            <table className="agents-modal-table">
              <thead>
                <tr>
                  <th></th>
                  <th>Campaign Name</th>
                  <th>Status</th>
                  <th>Phone</th>
                  <th>creation Date</th>
                </tr>
              </thead>
              <tbody>
                {adCampaignData.map((adCampaign) => (
                  <tr key={adCampaign.cmapaignId}>
                    <td>
                      <input type="checkbox" className="modal-checkbox" />
                    </td>
                    <td>{adCampaign.campaignName}</td>
                    <td>{adCampaign.campaignStatus}</td>
                    <td>{adCampaign.campaignPhone}</td>
                    <td>
                      {new Date(adCampaign.createdAt).toLocaleDateString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="d-flex-row-space-between w-100 p-1rem-1_5rem shadow-box-10">
          <button
            className="btn-cancel w-calc-50_minus_0_25rem"
            onClick={onClose}
          >
            <span>Cancel</span>
          </button>
          <button className="btn btn-primary w-calc-50_minus_0_25rem h-2_5rem">
            <span className="ff-inter fw-500 fs-0_875rem">Confirm</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdCampaignSelectionModal;
