import React, { useEffect, useState } from 'react';
import moment from 'moment';

import ConversationCharts from './ConversationCharts';
import { useAuth } from '../../../context/AuthContext';
import { getStatisticsById } from '../../../core/requests/requests';
import { StatisticsProps } from '../../../core/types/_models';

const ConversationChartsFunctions: React.FC = () => {
  const { currentUser } = useAuth();
  const [activePeriod, setActivePeriod] = useState<
    'weekly' | 'monthly' | 'yearly'
  >('weekly');
  const [queryString, setQueryString] = useState<string>('');
  const [statisticsData, setStatisticsData] = useState<StatisticsProps[]>([]);
  const currentUserId = currentUser?.id;

  const getQueryString = (activePeriod: 'weekly' | 'monthly' | 'yearly') => {
    const now = moment();
    let startDate = moment();
    let endDate = moment();
    let group = '';

    if (activePeriod === 'weekly') {
      startDate = now.clone().startOf('isoWeek'); // Assuming week starts on Monday
      endDate = now.clone().endOf('isoWeek');
    } else if (activePeriod === 'monthly') {
      startDate = now.clone().startOf('month');
      endDate = now.clone().endOf('month');
    } else if (activePeriod === 'yearly') {
      startDate = now.clone().startOf('year');
      endDate = now.clone().endOf('year');
      group = '&group=month';
    }

    const start = startDate.format('YYYY-MM-DD');
    const end = endDate.format('YYYY-MM-DD');

    return `?start=${start}&end=${end}${group}`;
  };

  const fetchStatistics = async () => {
    try {
      if (currentUserId) {
        const { data } = await getStatisticsById(currentUserId + queryString);
        if (data) {
          setStatisticsData(data);
        }
      }
    } catch (error) {
      console.error('Error fetching statistics', error);
    }
  };

  useEffect(() => {
    const query = getQueryString(activePeriod);
    setQueryString(query);
  }, [activePeriod]);

  useEffect(() => {
    if (queryString) {
      fetchStatistics();
    }
  }, [queryString]);

  return (
    <div className="d-flex-start-start w-100">
      <ConversationCharts
        activePeriod={activePeriod}
        setActivePeriod={setActivePeriod}
        statisticsData={statisticsData}
      />
    </div>
  );
};

export default ConversationChartsFunctions;
