import React from 'react';
import {
  ConversationsChart,
  DashboardLeadsCharts,
  ImpressionsChart,
  RepliesChart,
  WarmLeadsCharts,
} from './';

const DashboardCharts: React.FC = () => {
  return (
    <div className="d-flex-row w-100 h-18rem bg-white  mt-1_875rem b-radius-1_25rem">
      <DashboardLeadsCharts />
      <ImpressionsChart />
      <WarmLeadsCharts />
      <ConversationsChart />
      <RepliesChart />
    </div>
  );
};

export default DashboardCharts;
