import React from 'react';
import {
  ArrowUpRightGreenIcon,
  FacebookGraphicPNG,
  FacebookWhiteIcon,
  InstagramGraphicPNG,
  InstagramWhiteIcon,
  TikTokIcon,
  XGraphicPNG,
  XWhiteIcon,
  YouTubeGraphicPNG,
  YoutubeWhiteIcon,
} from '../../../assets';

const DashboardLeadsCharts: React.FC = () => {
  return (
    <div className="d-flex-start-start h-100 flex-grow-1  w-40 br-1-secondary">
      <div className="d-flex-row h-100 w-100 p-1_125rem-1_625rem">
        <div className="d-flex-start-start w-50 h-100">
          <h4 className="ff-inter c-title fs-1_125rem lh-1_5rem pt-1rem pb-2_625rem ls-_-0_0016rem fw-600">
            Leads
          </h4>
          <h1 className="ff-montserrat fw-600 c-black fs-2_5rem lh-2_5rem pb-0_5rem">
            13.1k
          </h1>
          <span className="ff-montserrat c-clicks fs-0_75rem pb-1_875rem">
            Clicks
          </span>
          <span className="ff-montserrat c-454545 lh-1_5rem fs-0_875rem pb-0_625rem">
            The number of views from your social media profiles
          </span>
          <div className="d-flex-row">
            <div className="d-flex-center">
              <ArrowUpRightGreenIcon />
            </div>
            <span className="ff-inter c-52a86e fw-600 fs-0_75rem ls-_-0_002rem lh-1_125rem">
              +4.11%
            </span>
            <span className="ff-inter c-5f5f61 fs-0_75rem ls-_-0_002rem lh-1_125rem pl-0_25rem">
              vs last 30 days
            </span>
          </div>
        </div>
        <div className="d-flex-end-end-row h-100 w-50">
          <div className="d-flex-end-start h-100">
            <div className="d-flex-end-start h-100 p-relative">
              <img
                alt=""
                src={InstagramGraphicPNG}
                className="h-90  w-1-875rem"
              />
              <div className="d-flex-center p-absolute bottom-0_285rem left-0_285rem">
                <InstagramWhiteIcon />
              </div>
            </div>
            <span className="ff-montserrat c-454545 fs-0_75rem pt-0_5rem">
              3124
            </span>
          </div>
          <div className="d-flex-end-start ml-0_5rem h-100">
            <div className="d-flex-end-start h-100 p-relative">
              <img
                alt=""
                src={FacebookGraphicPNG}
                className="h-50 w-1-875rem"
              />
              <div className="d-flex-center p-absolute bottom-0_285rem left-0_285rem">
                <FacebookWhiteIcon />
              </div>
            </div>
            <span className="ff-montserrat c-454545 fs-0_75rem pt-0_5rem">
              342
            </span>
          </div>
          <div className="d-flex-end-start ml-0_5rem h-100">
            <div className="d-flex-end-start h-100 p-relative">
              <img alt="" src={XGraphicPNG} className="h-50 w-1-875rem" />
              <div className="d-flex-center p-absolute bottom-0_285rem left-0_285rem">
                <XWhiteIcon />
              </div>
            </div>
            <span className="ff-montserrat c-454545 fs-0_75rem pt-0_5rem">
              8321
            </span>
          </div>
          <div className="d-flex-end-start ml-0_5rem h-100">
            <div className="d-flex-end-start h-100 p-relative">
              <img alt="" src={YouTubeGraphicPNG} className="h-50 w-1-875rem" />
              <div className="d-flex-center p-absolute bottom-0_285rem left-0_285rem">
                <YoutubeWhiteIcon />
              </div>
            </div>
            <span className="ff-montserrat c-454545 fs-0_75rem pt-0_5rem">
              412
            </span>
          </div>
          <div className="d-flex-end-start ml-0_5rem h-100">
            <div className="d-flex-center w-100 mb-0_375rem">
              <TikTokIcon className="w-1_25rem h-1_25rem" />
            </div>
            <button className="btn-add-dashboard">
              <span>+</span>
            </button>
            <div className="d-flex-center w-100">
              <span className="ff-montserrat c-454545 fs-0_75rem pt-0_5rem">
                0
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLeadsCharts;
