import React, { useState } from 'react';
import { ExportIcon, VerticalDotsIcon } from '../../../assets';

const ConversationsHeader: React.FC = () => {
  const [showExportDropdown, setShowExportDropdown] = useState(false);
  const triggerExportDropdown = () =>
    setShowExportDropdown(!showExportDropdown);

  const closeExportDropdown = () => setShowExportDropdown(false);
  return (
    <div className="d-flex-row-space-between w-100 mb-2rem">
      <h2 className="h2-primary">Conversations</h2>
      <div
        className="d-flex-center-end-row w-10rem p-relative "
        onClick={triggerExportDropdown}
      >
        <ExportIcon />
        <span className="hovered p-0_5rem c-1751d0 fs-0_875rem fw-500">
          Export
        </span>
        <VerticalDotsIcon className="icon-hovered" />
        {showExportDropdown && (
          <div className="d-flex-start-start w-100 p-absolute top-2_3rem b-radius-0_5rem b-1-light-gray shadow-box-8 bg-white p-0_5rem-0">
            <div
              className="d-flex-row w-100 p-0_5rem-1rem hovered"
              onClick={closeExportDropdown}
            >
              <span className="c-696974 fs-0_875rem">Export to PDF</span>
            </div>
            <div
              className="d-flex-row w-100 p-0_5rem-1rem hovered"
              onClick={closeExportDropdown}
            >
              <span className="c-696974 fs-0_875rem">Export to Excel</span>
            </div>
            <div
              className="d-flex-row w-100 p-0_5rem-1rem hovered"
              onClick={closeExportDropdown}
            >
              <span className="c-696974 fs-0_875rem">Expert to Word</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConversationsHeader;
