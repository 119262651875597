import React, { useState } from 'react';
import {
  AgentBioComponent,
  IntegrationsComponent,
  SettingsCalendarComponent,
  SettingsHeader,
  SettingsPayments,
} from './components';

const Settings: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>('agent-bio');

  return (
    <div className="d-flex-start-start w-100 p-1_875rem">
      <SettingsHeader activeTab={activeTab} setActiveTab={setActiveTab} />

      {activeTab === 'agent-bio' && <AgentBioComponent />}
      {activeTab === 'calendar' && <SettingsCalendarComponent />}
      {activeTab === 'integrations' && <IntegrationsComponent />}
      {activeTab === 'payments' && <SettingsPayments />}
    </div>
  );
};

export default Settings;
