import React from 'react';
import { ArrowDownRightRedIcon, RepliesChartPNG } from '../../../assets';

const RepliesChart: React.FC = () => {
  return (
    <div className="d-flex-start-start h-100 flex-grow-1 w-15">
      <div className="d-flex-start-start p-1_125rem-1_625rem h-100">
        <div className="d-flex-start-between w-100 h-100">
          <div className="d-flex-center p-relative w-6_56rem h-6_56rem">
            <img src={RepliesChartPNG} alt="" className="w-6_56rem h-6_56rem" />
            <div className="d-flex-center p-absolute left-0 top-0 w-6_56rem h-6_56rem">
              <span className="ff-montserrat c-replies fs-1_75rem lh-2rem fw-700">
                14
              </span>
              <span className="ff-montserrat c-clicks fs-0_75rem">deals</span>
            </div>
          </div>
          <div className="d-flex-start-start w-100">
            <h4 className="ff-montserrat c-262628 fw-600 fs-1_125rem ls-_-0_0026rem pb-1rem">
              Replies
            </h4>
            <span className="ff-montserrat c-454545 lh-1_5rem fs-0_875rem pb-0_625rem">
              The number of visitors for your website
            </span>
            <div className="d-flex-row">
              <div className="d-flex-center">
                <ArrowDownRightRedIcon />
              </div>
              <span className="ff-inter c-e73f3f fw-600 fs-0_75rem ls-_-0_002rem lh-1_125rem">
                -1.11%
              </span>
              <span className="ff-inter c-5f5f61 fs-0_75rem ls-_-0_002rem lh-1_125rem pl-0_25rem">
                vs last 30 days
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepliesChart;
