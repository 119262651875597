import React from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';

import { InfoIcon } from '../../../assets';
import { InitialBillingDetailsProps } from './settingsModels';
import {
  companyNameValidationSchema,
  countryValidationSchema,
  emailValidationSchema,
  streetValidationSchema,
} from '../../auth/components/ValidationSchemaItems';

const initialFormikValues: InitialBillingDetailsProps = {
  name: '',
  email: '',
  country: '',
  address: '',
  companyName: '',
  VATNumber: '',
  street: '',
  city: '',
  state: '',
  postalCode: '',
  additionalInfo: '',
};

const SettingsPaymentsBillingDetails: React.FC = () => {
  const handleSubmit = async (values: InitialBillingDetailsProps) => {
    try {
      console.log(values);
    } catch (error) {
      console.log(error);
    }
  };

  const validationSchema = Yup.object().shape({
    email: emailValidationSchema,
    country: countryValidationSchema,
    companyName: companyNameValidationSchema,
    street: streetValidationSchema,
  });

  return (
    <div className="d-flex-start-start w-100 bg-white shadow-box-9 b-radius-0_5rem p-1_5rem">
      <div className="d-flex-row mb-1rem">
        <span className="ff-inter c-111928 fw-700 fs-1_25rem">
          Billing details
        </span>
        <div className="d-flex-center ml-0_5rem icon-hovered">
          <InfoIcon />
        </div>
      </div>
      <Formik
        initialValues={initialFormikValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, isValid }) => (
          <Form className="w-100">
            <div className="d-flex-row-space-between w-100">
              <div className="d-flex-start-start w-calc-50_minus_0_5rem">
                <div className="d-flex-start-start w-100 mb-1rem p-relative">
                  <label htmlFor="name" className="payment-details-input-label">
                    Your name
                  </label>
                  <Field
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Bonnie Green"
                    className="payment-details-input w-100"
                  />
                </div>
                <div className="d-flex-start-start w-100 mb-1rem p-relative">
                  <label
                    htmlFor="country"
                    className="payment-details-input-label"
                  >
                    Country *
                  </label>
                  <Field
                    type="text"
                    id="country"
                    name="country"
                    placeholder="Billing from"
                    required
                    className="payment-details-input w-100"
                  />
                  {errors?.country && (
                    <div className="form-error">{errors?.country}</div>
                  )}
                </div>
                <div className="d-flex-start-start w-100 mb-1rem p-relative">
                  <label
                    htmlFor="companyName"
                    className="payment-details-input-label"
                  >
                    Company name *
                  </label>
                  <Field
                    type="text"
                    id="companyName"
                    name="companyName"
                    placeholder="Company name"
                    required
                    className="payment-details-input w-100"
                  />
                  {errors?.companyName && (
                    <div className="form-error">{errors?.companyName}</div>
                  )}
                </div>
                <div className="d-flex-start-start w-100 mb-1rem p-relative">
                  <label
                    htmlFor="street"
                    className="payment-details-input-label"
                  >
                    Street *
                  </label>
                  <Field
                    type="text"
                    id="street"
                    name="street"
                    placeholder="Billing from"
                    required
                    className="payment-details-input w-100"
                  />
                  {errors?.street && (
                    <div className="form-error">{errors?.street}</div>
                  )}
                </div>
                <div className="d-flex-start-start w-100 mb-1rem p-relative">
                  <label
                    htmlFor="state"
                    className="payment-details-input-label"
                  >
                    State/County
                  </label>
                  <Field
                    type="text"
                    id="state"
                    name="state"
                    placeholder="Billing from"
                    className="payment-details-input w-100"
                  />
                </div>
              </div>
              <div className="d-flex-start-start w-calc-50_minus_0_5rem">
                <div className="d-flex-start-start w-100 mb-1rem p-relative">
                  <label
                    htmlFor="email"
                    className="payment-details-input-label"
                  >
                    Your email *
                  </label>
                  <Field
                    type="text"
                    id="email"
                    name="email"
                    placeholder="name@company.com"
                    required
                    className="payment-details-input w-100"
                  />
                  {errors?.email && (
                    <div className="form-error">{errors?.email}</div>
                  )}
                </div>
                <div className="d-flex-start-start w-100 mb-1rem p-relative">
                  <label
                    htmlFor="address"
                    className="payment-details-input-label"
                  >
                    Address
                  </label>
                  <Field
                    type="text"
                    id="address"
                    name="address"
                    placeholder="Billing from"
                    className="payment-details-input w-100"
                  />
                </div>
                <div className="d-flex-start-start w-100 mb-1rem p-relative">
                  <label
                    htmlFor="VATNumber"
                    className="payment-details-input-label"
                  >
                    VAT number
                  </label>
                  <Field
                    type="text"
                    id="VATNumber"
                    name="VATNumber"
                    placeholder="DE42313253"
                    className="payment-details-input w-100"
                  />
                </div>
                <div className="d-flex-start-start w-100 mb-1rem p-relative">
                  <label htmlFor="city" className="payment-details-input-label">
                    Town/City
                  </label>
                  <Field
                    type="text"
                    id="city"
                    name="city"
                    placeholder="Town"
                    className="payment-details-input w-100"
                  />
                </div>
                <div className="d-flex-start-start w-100 mb-1rem p-relative">
                  <label
                    htmlFor="postalCode"
                    className="payment-details-input-label"
                  >
                    Zip/Postal code
                  </label>
                  <Field
                    type="text"
                    id="postalCode"
                    name="postalCode"
                    placeholder="Billing from"
                    className="payment-details-input w-100"
                  />
                </div>
              </div>
            </div>
            <div className="d-flex-start-start w-100 mb-1rem">
              <label
                htmlFor="additionalInfo"
                className="payment-details-input-label"
              >
                Additional info
              </label>
              <Field
                as="textarea"
                id="additionalInfo"
                name="additionalInfo"
                className="payment-details-textarea w-100"
                placeholder="Receipt Info (optional)"
              />
            </div>
            <button
              className="btn-subscriptions-primary w-8_625rem "
              type="submit"
              disabled={!isValid}
            >
              Save changes
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SettingsPaymentsBillingDetails;
