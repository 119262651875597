import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { defaultStaticRanges } from '.';
import {
  DateRangeSelectorProps,
  SelectedRangeProps,
} from './startCampaignModels';
import { getFormattedStringFromDate } from '../../../helpers/campaignHelpers';

const DateRangeSelector: React.FC<DateRangeSelectorProps> = ({
  closeDateRangeSelector,
  formikValues,
  setFormikValues,
}) => {
  const [selectedRange, setSelectedRange] = useState<SelectedRangeProps[]>([
    {
      startDate: formikValues?.startDate
        ? new Date(formikValues?.startDate)
        : new Date(),
      endDate: formikValues?.endDate
        ? new Date(formikValues?.endDate)
        : new Date(),
      key: 'selection',
    },
  ]);
  /*const minDateThreeYearsAgo = new Date(
    moment().subtract(3, 'years').format('Y-MM-DD')
  );
  */
  const currentDate = new Date(moment().format('Y-MM-DD'));

  const handleSelect = (ranges: any) => {
    const { selection } = ranges;
    setSelectedRange([selection]);
  };

  const onClickClear = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    closeDateRangeSelector();
    if (event) {
      event.stopPropagation();
    }
  };

  const handleApply = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setFormikValues((prevState) => ({
      ...prevState,
      startDate: getFormattedStringFromDate(selectedRange[0]?.startDate),
      endDate: getFormattedStringFromDate(selectedRange[0]?.endDate),
    }));
    closeDateRangeSelector();
    if (event) {
      event.stopPropagation();
    }
  };

  return (
    <>
      <div className="d-flex-center p-absolute top-4_5rem z2">
        <DateRangePicker
          onChange={handleSelect}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={selectedRange}
          direction="horizontal"
          minDate={currentDate}
          //maxDate={currentDate}
          staticRanges={defaultStaticRanges}
        />
        <div className="d-flex-center-end-row w-100 bg-white pb-1rem ">
          <div
            className="btn-component-header-primary mr-1rem"
            onClick={handleApply}
          >
            Apply
          </div>
          <div
            className="btn-component-header-secondary mr-0_5rem"
            onClick={onClickClear}
          >
            Cancel
          </div>
        </div>
      </div>
    </>
  );
};

export default DateRangeSelector;
