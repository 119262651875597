export const API_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_API_URL_LOCAL;

export const PUBLIC_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_PUBLIC_URL
    : process.env.REACT_APP_PUBLIC_URL_LOCAL;

export const FRONT_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_FRONT_URL
    : process.env.REACT_APP_FRONT_URL_LOCAL;

export const HUBSPOT_CLIENT_ID = process.env.REACT_APP_HUBSPOT_CLIENT_ID;
export const HUBSPOT_CLIENT_SECRET =
  process.env.REACT_APP_HUBSPOT_CLIENT_SECRET;
export const HUBSPOT_URL = process.env.REACT_APP_HUBSPOT_URL;

export const HUBSPOT_REDIRECT_URI =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_HUBSPOT_REDIRECT_URI
    : process.env.REACT_APP_HUBSPOT_REDIRECT_URI_LOCAL;

export const SOCKET_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_SOCKET_URL
    : process.env.REACT_APP_SOCKET_URL_LOCAL;
