import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../context/AuthContext';
import { AvatarIcon, HeaderPlusIcon, LogoutIcon } from '../../assets';
//import { StartCampaignModal } from '../../pages/campaigns/components';
import { useAppData } from '../../context/AppContext';
import { campaignViewValues } from '../../config/CampaignsConfig';
import { FRONT_URL } from '../../helpers/envProcessor';

const Header: React.FC = () => {
  const navigate = useNavigate();

  const { setAuthLogin, currentUser } = useAuth();
  //const [showStartCampaignModal, setShowStartCampaignModal] = useState(false);
  const { setCurrentStartCampaignView, setCurrentCampaign } = useAppData();
  const [avatarURL, setAvatarURL] = useState<string>('');

  const handleLogout = () => {
    setAuthLogin(null);
    localStorage.removeItem('authLogin');
    localStorage.removeItem('currentUser');
    navigate('/');
  };

  const handleOpenCreateCampaign = () => {
    setCurrentCampaign(null);
    setCurrentStartCampaignView(campaignViewValues?.settings);
    navigate('/leads/start-new-campaign');
  };

  /*const openStartCampaignModal = () => {
    setShowStartCampaignModal(true);
  };*/

  /*const closeStartCampaignModal = () => {
    setShowStartCampaignModal(false);
  };*/

  const handleNavigateToProfile = () => {
    navigate('/profile');
  };

  const handleNavigateToHelp = () => {
    navigate('/help');
  };

  useEffect(() => {
    if (FRONT_URL && currentUser?.avatar) {
      // Force the avatar URL to update with a cache buster
      setAvatarURL(
        `${FRONT_URL}${currentUser.avatar}?timestamp=${new Date().getTime()}`
      );
    }
  }, [currentUser]);

  return (
    <>
      <div className="d-flex-center-end-row  p-absolute w-100 h-4_375rem bg-white bb-1 top-0 left-0">
        <button
          className="btn-header-primary mr-0_625rem"
          onClick={handleOpenCreateCampaign}
        >
          <HeaderPlusIcon className="mr-0_5rem" />
          <span>New Campaign</span>
        </button>
        <button
          className="btn-header-secondary mr-1_25rem"
          onClick={handleNavigateToHelp}
        >
          Contact help
        </button>
        <div
          className="d-flex-row h-2rem mr-1_25rem icon-hovered"
          onClick={handleNavigateToProfile}
        >
          {currentUser?.avatar ? (
            <img
              src={avatarURL}
              className="w-2rem h-2rem obj-fit-cover b-radius-1rem"
              alt=""
            />
          ) : (
            <AvatarIcon className="w-2rem h-2rem" />
          )}
          <div className="d-flex-start-start h-2rem pl-0_625rem">
            <span className="header-name-title pb-0_375rem">
              {currentUser?.firstName} {currentUser?.lastName}
            </span>
            <span className="header-job-title">{currentUser?.jobPosition}</span>
          </div>
        </div>
        <div className="d-flex-center pr-2rem" onClick={handleLogout}>
          <LogoutIcon className="logout-icon icon-hover" />
        </div>
      </div>
      {/*showStartCampaignModal && (
        <StartCampaignModal closeStartCampaignModal={closeStartCampaignModal} />
      )*/}
    </>
  );
};

export default Header;
