import React from 'react';
import { ChatAvatarIcon, SampleBackgroundPNG } from '../../../assets';

const EditTemplateView: React.FC = () => {
  return (
    <div className="d-flex-start-start pb-1_5rem w-100 ">
      <div className="d-flex-start-start-row p-1rem-0 w-100 bb-1">
        <div className="d-flex-start-start w-55 mr-1_75rem p-1rem-0">
          <div className="d-flex-row w-100">
            <div className="d-flex-center w-3rem h-3rem b-radius-0_75rem overflow-h mr-1rem">
              <img
                className="w-3rem h-3rem obj-fit-cover"
                alt=""
                src={SampleBackgroundPNG}
              />
            </div>
            <button className="btn-add-image">
              <span className="pl-1rem">Upload image</span>
            </button>
            <span className="text-inter-regular-description pl-0_75rem">
              JPG or PNG. 1MB max
            </span>
          </div>
          <div className="d-flex-start-start w-100 pt-1rem">
            <span className="text-inter-regular pb-0_5rem">Template title</span>
            <div className="d-flex-row w-100">
              <input placeholder="Title" className="campaign-convert-input " />
            </div>
          </div>
          <div className="d-flex-start-start w-100 pt-1rem">
            <span className="text-inter-regular pb-0_5rem">Content</span>
            <div className="d-flex-row w-100">
              <textarea
                placeholder="Content"
                className="campaign-template-textarea w-100"
              />
            </div>
          </div>
          <button className="mt-1rem btn-roboto-primary">Start</button>
        </div>
        <div className="d-flex-start-start w-45 bg-header b-radius-0_625rem pt-1_75rem pl-1_75rem p-relative">
          <div className="d-flex-start-start bg-white btl-radius-1_125rem bl-1 bt-1 w-100 pt-2_5rem pl-2_5rem pb-1rem">
            <div className="d-flex-start-start-row  w-100">
              <div className="d-flex-center bg-primary-message p-0_75rem mr-0_75rem btl-radius-0_875rem btr-radius-0_875rem bbl-radius-0_875rem ">
                <span className="text-message-inter-regular">
                  Et ipsum tincidunt justo nunc. Porttitor non enim id
                  scelerisque cursus et vel et. Etiam dictum congue adipiscing
                  commodo nunc. Aliquet nunc dignissim in pellentesque nisi
                  donec eu nunc.
                </span>
              </div>
              <div className="d-flex-center mr-0_5rem">
                <ChatAvatarIcon />
              </div>
            </div>
          </div>
          <div className="gradient-overlay"></div>
        </div>
      </div>
    </div>
  );
};

export default EditTemplateView;
