import React, { useState } from 'react';
import {
  InfoIcon,
  MasterCardIcon,
  PayPalIcon,
  PlusAddIcon,
  VisaIcon,
} from '../../../assets';

const SettingsPaymentsMethods: React.FC = () => {
  const [activeMethod, setActiveMethod] = useState('visa');

  const handleChangeActiveMethod = (method: string) => {
    setActiveMethod(method);
  };

  return (
    <div className="d-flex-start-start w-calc-50_minus_0_75rem bg-white shadow-box-9 b-radius-0_5rem p-1_5rem">
      <div className="d-flex-row mb-1_5rem">
        <span className="ff-inter c-111928 fw-700 fs-1_25rem">
          Payment methods
        </span>
        <div className="d-flex-center ml-0_5rem icon-hovered">
          <InfoIcon />
        </div>
      </div>
      <div
        className={`${activeMethod === 'visa' ? 'active' : ''} payment-method-item`}
        onClick={() => handleChangeActiveMethod('visa')}
      >
        <div className="payment-method-info">
          <div className="payment-method-checkbox-row">
            <div className="payment-method-checkbox"></div>
            <div className="d-flex-start-start mb-1rem">
              <span className="ff-inter c-111928 fw-500 pb-0_375rem lh-1rem">
                Visa ending in 7658
              </span>
              <span className="c-6b7280 fs-0_75rem">Expiry 10/2024</span>
            </div>
          </div>
          <div className="d-flex-row">
            <div className="d-flex-center br-1 hovered pr-0_5rem">
              <span className="ff-inter c-6b7280 fw-500 fs-0_875rem">
                Delete
              </span>
            </div>
            <div className="d-flex-center  hovered pl-0_5rem">
              <span className="ff-inter c-6b7280 fw-500 fs-0_875rem">Edit</span>
            </div>
          </div>
        </div>
        <div className="d-flex-center">
          <VisaIcon />
        </div>
      </div>

      <div
        className={`${activeMethod === 'master-card' ? 'active' : ''} payment-method-item`}
        onClick={() => handleChangeActiveMethod('master-card')}
      >
        <div className="payment-method-info">
          <div className="payment-method-checkbox-row">
            <div className="payment-method-checkbox"></div>
            <div className="d-flex-start-start mb-1rem">
              <span className="ff-inter c-111928 fw-500 pb-0_375rem lh-1rem">
                Mastercard ending in 8429
              </span>
              <span className="c-6b7280 fs-0_75rem">Expiry 04/2026</span>
            </div>
          </div>
          <div className="d-flex-row">
            <div className="d-flex-center br-1 hovered pr-0_5rem">
              <span className="ff-inter c-6b7280 fw-500 fs-0_875rem">
                Delete
              </span>
            </div>
            <div className="d-flex-center  hovered pl-0_5rem">
              <span className="ff-inter c-6b7280 fw-500 fs-0_875rem">Edit</span>
            </div>
          </div>
        </div>
        <div className="d-flex-center">
          <MasterCardIcon />
        </div>
      </div>

      <div
        className={`${activeMethod === 'paypal' ? 'active' : ''} payment-method-item`}
        onClick={() => handleChangeActiveMethod('paypal')}
      >
        <div className="payment-method-info">
          <div className="payment-method-checkbox-row">
            <div className="payment-method-checkbox"></div>
            <div className="d-flex-start-start mb-1rem">
              <span className="ff-inter c-111928 fw-500 lh-1rem">
                Paypal account
              </span>
            </div>
          </div>
          <div className="d-flex-row">
            <div className="d-flex-center br-1 hovered pr-0_5rem">
              <span className="ff-inter c-6b7280 fw-500 fs-0_875rem">
                Delete
              </span>
            </div>
            <div className="d-flex-center  hovered pl-0_5rem">
              <span className="ff-inter c-6b7280 fw-500 fs-0_875rem">Edit</span>
            </div>
          </div>
        </div>
        <div className="d-flex-center">
          <PayPalIcon />
        </div>
      </div>

      <button className="btn-subscriptions-secondary w-100 mb-1_5rem">
        <div className="d-flex-center mr-0_5rem">
          <PlusAddIcon />
        </div>
        <span>Add new payment method</span>
      </button>
      <button className="btn-subscriptions-primary w-8_625rem ">
        Save changes
      </button>
    </div>
  );
};

export default SettingsPaymentsMethods;
