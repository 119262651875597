import React, { useState } from 'react';
import AuthorizationComponent from './HubSpotAuth';

const SyncLeadsButton: React.FC = () => {
  const [syncLeads, setSyncLeads] = useState<boolean>(false);

  return (
    <div className="d-flex-center ml-1rem">
      <button
        className="btn-import-leads fw-600"
        onClick={() => setSyncLeads(!syncLeads)}
      >
        <span>Sync Leads</span>
      </button>
      {syncLeads && <AuthorizationComponent />}
    </div>
  );
};

export default SyncLeadsButton;
